<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useLoginStore } from '@/stores/LoginStore';
import { RestApiRequest } from '@/restApiRequest';
import { validatePassword } from '@/components/passwordchange/PasswordChange';
import PasswordCriteria from '@/components/passwordchange/PasswordCriteria.vue';

const oldPassword = ref('');
const newPassword = ref('');
const confirmPassword = ref('');
const message = ref('');
const msgIcon = ref('');
const textColor = ref('');

const loginStore = useLoginStore();

async function onSubmit() {
  await RestApiRequest.changePassword(
    { oldPassword: oldPassword.value, newPassword: newPassword.value },
    loginStore.csrfToken,
    success,
    failure,
  );
}

const success = (response: string) => {
  resetInputFields();
  message.value = 'Success: ' + response;
  msgIcon.value = 'check_circle';
  textColor.value = 'text-positive';
};

const failure = (errormsg: string) => {
  resetInputFields();
  message.value = 'Error: ' + errormsg;
  msgIcon.value = 'error_outline';
  textColor.value = 'text-negative';
};

const resetInputFields = () => {
  oldPassword.value = '';
  newPassword.value = '';
  confirmPassword.value = '';
};

const newPasswordValid = ref(false);
const passwordsMatch = ref(true);

watch(newPassword, (newValue) => {
  newPasswordValid.value = validatePassword(newValue);
  passwordsMatch.value = newValue === confirmPassword.value;
});

watch(confirmPassword, (newValue) => {
  passwordsMatch.value = newValue === newPassword.value;
});

const isValidInput = computed(() => {
  return (
    newPassword.value !== '' &&
    confirmPassword.value !== '' &&
    newPasswordValid.value &&
    passwordsMatch.value
  );
});

const isTyping = computed(() => {
  return oldPassword.value !== '' || newPassword.value !== '' || confirmPassword.value !== '';
});

watch(isTyping, (newValue) => {
  if (newValue) {
    message.value = '';
  }
});
</script>

<template>
  <q-card class="my-card">
    <q-img src="/login.png">
      <div class="absolute-bottom text-h5 text-weight-light">NEWSIMweb Portal</div>
    </q-img>
    <q-form ref="passwordForm" autofocus @submit.prevent="onSubmit">
      <q-card-section>
        <div
          v-show="message.length"
          data-testid="info-message"
          class="label text-subtitle1 text-weight-light"
          :class="textColor"
        >
          <q-icon :name="msgIcon" />
          {{ message }}
        </div>
        <q-input
          v-model="oldPassword"
          label="Current Password *"
          type="password"
          lazy-rules
        ></q-input>
        <q-input v-model="newPassword" label="New Password *" type="password" lazy-rules></q-input>
        <q-input
          v-model="confirmPassword"
          label="Confirm Password *"
          type="password"
          lazy-rules
        ></q-input>
        <div v-if="!passwordsMatch" class="text-negative q-mt-sm" data-testid="no-match">
          Confirm Password do not match New Password!
        </div>
      </q-card-section>
      <q-card-actions class="q-pa-md" align="right">
        <q-btn :disabled="!isValidInput" type="submit" color="primary">Change</q-btn>
      </q-card-actions>
      <q-separator color="primary" />
      <q-card-section>
        <PasswordCriteria :new-password="newPassword" />
      </q-card-section>
    </q-form>
  </q-card>
</template>

<style lang="sass" scoped>
.my-card
  width: 100%
  max-width: 400px
</style>
