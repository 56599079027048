import { v4 as uuidv4 } from 'uuid';
import type { ExerciseInfo } from '@/model/ExerciseInfo';

type ExerciseDataCallback = (value: ExerciseInfo[]) => void;

class BaseAvailableExerciseProvider {
  protected exerciseInfos: ExerciseInfo[];

  protected exerciseCallbacks: Map<string, ExerciseDataCallback>;
  protected id: string;

  constructor(id: string) {
    this.id = id;
    this.exerciseInfos = [];
    this.exerciseCallbacks = new Map<string, ExerciseDataCallback>();
  }

  subscribeForExerciseMessages(callback: ExerciseDataCallback): string {
    console.debug(`${this.id} : subscribe for exercise messages`);
    const subscriptionId: string = uuidv4();
    this.exerciseCallbacks.set(subscriptionId, callback);
    return subscriptionId;
  }

  unsubscribeFromExerciseMessages(subscriptionId: string) {
    console.debug(`${this.id} : unsubscribe from exercise messages`);
    this.exerciseCallbacks.delete(subscriptionId);
  }

  start(): void {} // empty implementation
  stop(): void {} // empty implementation
}

export { BaseAvailableExerciseProvider, type ExerciseDataCallback };
