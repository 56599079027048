<script setup lang="ts">
const $props = defineProps({
  toolTipText: {
    type: String,
    required: true,
  },
});

const toolTipText = $props.toolTipText;
</script>

<template>
  <div>
    <slot name="button"></slot>
    <q-tooltip self="bottom left" :delay="Number(700)">
      {{ toolTipText }}
    </q-tooltip>
  </div>
</template>

<style scoped lang="sass"></style>
