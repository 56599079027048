import { BaseExerciseSessionProvider } from '@/components/overview/BaseExerciseSessionProvider';
import { RestApiRequest } from '@/restApiRequest';
import type { SessionInfo } from '@/model/SessionInfo';
import { useLoginStore } from '@/stores/LoginStore';

/**
 * Receive allSessionInfo message. Inform listener.
 * messages in reactive member.
 */
class ExerciseSessionProvider extends BaseExerciseSessionProvider {
  private timer: number | undefined | ReturnType<typeof setInterval> = undefined;
  private readonly timeout: number | undefined;
  private loginStore = useLoginStore();

  /**
   *
   * @param id The ID (name).
   * @param timeout Poll timeout in msec
   */
  constructor(id: string, timeout: number) {
    super(id);
    this.timeout = timeout;
  }

  /**
   * Start polling rest api
   * @override BaseExerciseSessionProvider::start
   */
  async start() {
    await this.requestData();
    if (this.timer == undefined) {
      this.timer = setInterval(this.requestData.bind(this), this.timeout);
    }
  }

  /**
   * Stop polling the rest api
   * @override BaseExerciseSessionProvider::stop
   */
  stop() {
    if (this.timer != undefined) {
      clearInterval(this.timer);
    }
  }

  // timer callback
  private async requestData() {
    const success = this.onData.bind(this);
    const csrfToken = this.loginStore.csrfToken;
    try {
      await RestApiRequest.getAllSessionInfo(csrfToken, success);
    } catch (e) {
      this.stop();
      throw e;
    }
  }

  private onData(sessions: SessionInfo[]) {
    this.sessionInfos = sessions;

    for (const callback of this.sessionCallbacks.values()) {
      callback(this.sessionInfos);
    }
  }
}

export { ExerciseSessionProvider };
