<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, type PropType, type Ref, ref } from 'vue';
import ListElement from '@/components/overview/ListElement.vue';
import ActionButton from '@/components/overview/ActionButton.vue';
import router from '@/router';
import type { ActiveListFilters } from '@/components/overview/ListFilters';
import {
  BaseExerciseSessionProvider,
  type SessionDataCallback,
} from '@/components/overview/BaseExerciseSessionProvider';
import { RestApiRequest } from '@/restApiRequest';
import { useLoginStore } from '@/stores/LoginStore';
import {
  setButtonColor,
  setButtonText,
  setTooltipText,
} from '@/components/overview/ActiveExerciseActionButton';
import type { SessionInfo } from '@/model/SessionInfo';
import { isRunning } from '@/model/SimulatorSessionState';
import {
  type ExerciseSessionData,
  getExerciseSessionData,
} from '@/components/overview/ExerciseSessionData';
import { HttpError } from '@/connection/HttpError';
import { errorDialog, multipleSessionsDialog } from '@/components/dialogs/dialogs';

const $props = defineProps({
  filters: {
    type: Object as PropType<ActiveListFilters>,
    required: true,
  },
  exerciseSessions: {
    type: BaseExerciseSessionProvider,
    required: true,
  },
});

const loginStore = useLoginStore();

const exerciseSessionData: Ref<ExerciseSessionData[]> = ref([]);

const updateSessionMessages: SessionDataCallback = (sessions: SessionInfo[]) => {
  exerciseSessionData.value = getExerciseSessionData(sessions);
};

const exerciseSessionProvider: BaseExerciseSessionProvider = $props.exerciseSessions;
let subscriptionId: string = '';

onMounted(() => {
  subscriptionId = exerciseSessionProvider.subscribeForSessionMessages(updateSessionMessages);
  exerciseSessionProvider.start();
});

onBeforeUnmount(() => {
  exerciseSessionProvider.stop();
  exerciseSessionProvider.unsubscribeFromSessionMessages(subscriptionId);
});

const filteredExerciseSessions = computed(() =>
  exerciseSessionData.value
    .filter((data) => joinableExercise(data))
    .filter((data) => !$props.filters.onlyRunningExercises || isRunning(data.sessionState))
    .filter((data) => matchTextFilter(data)),
);

// Only joinable exercises are shown in ActiveExerciseList.
// must be deleted, when coach-mode will be available
const joinableExercise = (data: ExerciseSessionData): boolean => {
  return data.sessionState === 'SETUP' || data.sessionState === 'NOTRUNNINGYET';
};

function matchTextFilter(data: ExerciseSessionData): boolean {
  if ($props.filters.filterText.length == 0) {
    return true;
  }
  const filterTextUpperCase = $props.filters.filterText.toUpperCase();
  if (data.description.toUpperCase().includes(filterTextUpperCase)) {
    return true;
  }
  if (data.exerciseName.toUpperCase().includes(filterTextUpperCase)) {
    return true;
  }
  if (data.sessionUsers.toString().toUpperCase().includes(filterTextUpperCase)) {
    return true;
  }
  if (data.simulationMode.includes(filterTextUpperCase)) {
    return true;
  }

  return false;
}

let lockJoinAction = false;

function joinSessionAction(data: ExerciseSessionData) {
  if (lockJoinAction) {
    return;
  }
  lockJoinAction = true;
  const sessionId: string = data.id;
  if (isRunning(data.sessionState)) {
    router
      .push({
        name: 'backstage',
        params: { sessionId: sessionId, exerciseName: data.exerciseName },
      })
      .catch();
    return;
  }

  RestApiRequest.joinSession(sessionId, loginStore.csrfToken)
    .then((successSessionId) =>
      router.push({
        name: 'lobby',
        params: { sessionId: successSessionId, exerciseName: data.exerciseName },
      }),
    )
    .catch((error: unknown) => {
      if (!(error instanceof HttpError)) {
        errorDialog(`Failed to join session! Reason: ${error}`).onOk(() => {
          lockJoinAction = false;
        });
        return;
      }
      if (!error.jsonBody || error.jsonBody['errorType'] !== 'MultipleSessions') {
        throw error;
      }

      const existingSessionId = error.jsonBody['sessionId'];
      RestApiRequest.getSessionInfo(loginStore.csrfToken, existingSessionId)
        .then((sessionInfo) => showMultipleSessionWarning(existingSessionId, sessionInfo))
        .catch(() => showMultipleSessionWarning(existingSessionId, undefined));
    });
}

function showMultipleSessionWarning(
  existingSessionId: string,
  sessionInfo: SessionInfo | undefined,
) {
  multipleSessionsDialog()
    .onOk(async () => {
      await router.push({
        name: 'lobby',
        params: {
          sessionId: existingSessionId,
          exerciseName: sessionInfo ? sessionInfo.exercise.name : 'N/A',
        },
      });
    })
    .onCancel(() => {
      lockJoinAction = false;
    });
}
</script>

<template>
  <q-scroll-area data-testid="active-exercise-list">
    <div class="q-ma-md">
      <!--<div class="gt-md border-dist-top" />-->
      <q-list separator>
        <q-item
          v-for="exerciseSession in filteredExerciseSessions"
          :key="exerciseSession.id"
          class="q-card q-card--bordered q-mb-sm"
        >
          <ListElement>
            <template #first-line>
              <q-item-label class="exercise-text" lines="1">
                <span
                  >{{ exerciseSession.exerciseName }} -
                  {{ exerciseSession.simulationMode.toString() }}
                </span>
              </q-item-label>
            </template>
            <template #second-line>
              <q-item-label caption
                >User: {{ exerciseSession.sessionUsers.join('; ') }}
              </q-item-label>
            </template>
            <template #additional>
              <q-icon v-if="exerciseSession.hasVrr" name="record_voice_over" />
            </template>
            <template #action-button>
              <ActionButton :tool-tip-text="setTooltipText(exerciseSession.sessionState)">
                <template #button>
                  <q-btn
                    class="btn-small"
                    :class="setButtonColor(exerciseSession)"
                    @click="joinSessionAction(exerciseSession)"
                  >
                    {{ setButtonText(exerciseSession) }}
                  </q-btn>
                </template>
              </ActionButton>
            </template>
          </ListElement>
        </q-item>
      </q-list>
    </div>
  </q-scroll-area>
</template>

<style scoped lang="sass">
.border-dist-top
  padding-top: 37px
</style>
