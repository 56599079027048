<script setup lang="ts">
import { onBeforeMount } from 'vue';
import { useLoginStore } from '@/stores/LoginStore';
import PasswordChangeForm from '@/components/passwordchange/PasswordChangeForm.vue';

const loginStore = useLoginStore();

function redirect() {
  if (loginStore.isLoggedIn) {
    console.log('logged in');
    return;
  }
  console.log('not logged in');
}

onBeforeMount(redirect);
</script>

<template>
  <q-page class="flex flex-center">
    <PasswordChangeForm />
  </q-page>
</template>
