import { FetchSessionInfo } from '@/components/helper/FetchSessionInfo';

/**
 * Class to handle the periodic data fetching and parsing for a simulator session in backstage.
 *
 * @param sessionId : string session id to fetch data for
 * @param csrfToken : string the current CSRF token
 * @param userName : string the current username
 *
 * @returns sessionInfo : Ref<SessionInfo | undefined> the last session info retrieved
 * @returns participants : Ref<Participants> the sessions participants
 * @returns isHost : Ref<boolean> true if the current user is the host of the session
 * @returns userIsInSession : Ref<boolean> true if the current user is a participant of the running session (or host)
 * @returns sessionError : Ref<HttpError | undefined> error if the request for the session was unsuccessful
 * @returns sessionInfoScheduler : ScheduledCall<void> reference to the scheduler polling the backend, use to stop the scheduler on page unload
 */
export class FetchSessionInfoBackstage extends FetchSessionInfo {
  get() {
    return {
      sessionInfo: this.sessionInfo,
      participants: this.participants,
      isHost: this.isHost,
      userIsInSession: this.userIsInSession,
      sessionError: this.sessionError,
      sessionInfoScheduler: this.sessionInfoScheduler,
      sessionErrorMsg: this.sessionErrorMsg,
    };
  }
}
