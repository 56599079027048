<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{ newPassword: string }>();

const passwordCriteria = computed(() => {
  return {
    length: props.newPassword.length >= 8 && props.newPassword.length <= 30,
    capital: /[A-Z]/.test(props.newPassword),
    small: /[a-z]/.test(props.newPassword),
    number: /[0-9]/.test(props.newPassword),
    symbol: /[@$!%*?&]/.test(props.newPassword),
  };
});
</script>

<template>
  <div class="password-criteria q-pa-xs">
    <div class="q-mb-xs text-bold">Password Policy:</div>
    <div>
      <q-icon
        :name="passwordCriteria.length ? 'check_circle' : 'cancel'"
        :color="passwordCriteria.length ? 'positive' : 'negative'"
      ></q-icon>
      Must be between 8 and 30 characters long.
    </div>
    <div>
      <q-icon
        :name="passwordCriteria.capital ? 'check_circle' : 'cancel'"
        :color="passwordCriteria.capital ? 'positive' : 'negative'"
      ></q-icon>
      Must contain at least one capital letter.
    </div>
    <div>
      <q-icon
        :name="passwordCriteria.small ? 'check_circle' : 'cancel'"
        :color="passwordCriteria.small ? 'positive' : 'negative'"
      ></q-icon>
      Must contain at least one small letter.
    </div>
    <div>
      <q-icon
        :name="passwordCriteria.number ? 'check_circle' : 'cancel'"
        :color="passwordCriteria.number ? 'positive' : 'negative'"
      ></q-icon>
      Must contain at least one number.
    </div>
    <div>
      <q-icon
        :name="passwordCriteria.symbol ? 'check_circle' : 'cancel'"
        :color="passwordCriteria.symbol ? 'positive' : 'negative'"
      ></q-icon>
      Must contain at least one symbol from `@$!%*?&`
    </div>
  </div>
</template>

<style scoped lang="sass"></style>
