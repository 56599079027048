<script setup lang="ts">
import type { DashboardImageInfo } from '@/model/DashboardImageInfo';
import type { QTableColumn } from 'quasar';

interface Props {
  imageInfos: DashboardImageInfo[];
}

const props = defineProps<Props>();

const columns: QTableColumn[] = [
  {
    name: 'name',
    required: true,
    label: 'Image Name',
    align: 'left',
    field: (row) => row.name,
    format: (val) => `${val}`,
    sortable: true,
  },
  { name: 'version', align: 'left', label: 'Version', field: 'version', sortable: true },
];
const rows = props.imageInfos;

const initialPagination = {
  sortBy: 'name',
  descending: false,
  page: 1,
  rowsPerPage: 10,
};
</script>

<template>
  <div class="q-pa-md" data-testid="image-list">
    <q-table
      class="table-layout"
      title="List of Images"
      dense
      :rows="rows"
      :columns="columns"
      row-key="name"
      :pagination="initialPagination"
    />
  </div>
</template>

<style scoped lang="sass"></style>
