<script setup lang="ts">
import { ref } from 'vue';
import type { DashboardPodInfo } from '@/model/DashboardPodInfo';
import type { QTableColumn } from 'quasar';

interface Props {
  podInfos: DashboardPodInfo[];
}

const propData = defineProps<Props>();
const columns: QTableColumn[] = [
  {
    name: 'name',
    required: true,
    label: 'Pod Name',
    align: 'left',
    field: (row) => row.name,
    format: (val) => `${val}`,
    sortable: true,
  },
  { name: 'sessionId', align: 'left', label: 'Session ID', field: 'sessionId', sortable: true },
  { name: 'upTime', align: 'left', label: 'Uptime', field: 'upTime', sortable: true },
  { name: 'status', align: 'left', label: 'Status', field: 'status', sortable: true },
];

const rows = propData.podInfos;

const pagination = ref({
  sortBy: 'sessionId',
  rowsPerPage: 0,
});

const getTextColor = (isOrphaned: boolean, status: string) => {
  if (isOrphaned && status !== 'Terminating') {
    return 'text-red';
  } else if (status === 'Terminating') {
    return 'text-orange';
  }
  return 'text-black';
};
</script>

<template>
  <div class="q-pa-md" data-testid="pod-list">
    <q-table
      v-model:pagination="pagination"
      class="table-layout sticky-virtscroll-table-layout"
      title="List of Pods"
      dense
      :rows="rows"
      :columns="columns"
      row-key="name"
      virtual-scroll
      :rows-per-page-options="[0]"
      :virtual-scroll-sticky-size-start="48"
      no-data-label="no active session pods"
    >
      <template #no-data="{ message }">
        <div class="row flex-center q-gutter-sm">
          <q-icon size="sm" name="playlist_add_check" />
          <span>
            {{ message }}
          </span>
        </div>
      </template>
      <template #body-cell-sessionId="props">
        <q-td :props="props">
          <div :class="getTextColor(props.row.isOrphaned, props.row.status)">
            {{ props.value }}
          </div>
        </q-td>
      </template>
    </q-table>
  </div>
</template>

<style scoped lang="sass">
:deep(.sticky-virtscroll-table-layout)
  height: 350px

  thead tr th
    position: sticky
    z-index: 1

  thead tr:last-child th
    top: 48px

  thead tr:first-child th
    top: 0

  tbody
    scroll-margin-top: 48px
</style>
