import type { Credentials } from '@/model/Credentials';
import type { Authentication } from '@/model/Authentication.ts';
import type { PasswordChange } from '@/model/PasswordChange';
import type { ExerciseInfo } from '@/model/ExerciseInfo';
import type { ExerciseList } from '@/model/ExerciseList';
import type { SessionInfo } from '@/model/SessionInfo';
import type { WorkspaceAssignmentEvent } from '@/model/WorkspaceAssignmentEvent';
import type { WorkspaceAssignmentCommand } from '@/model/WorkspaceAssignmentCommand';
import type { DashboardInfo } from '@/model/DashboardInfo';
import type { SimulationTimes } from '@/model/SimulationTimes.ts';
import { HttpError } from '@/connection/HttpError';

class RestApiRequest {
  static getRootUrl() {
    const url: string = `${import.meta.env.VITE_NEWSIMWEB_API_ROOT || ''}`;
    return url;
  }

  static LOGIN: string = RestApiRequest.getRootUrl() + '/rest/v1/login';
  static LOGOUT: string = RestApiRequest.getRootUrl() + '/rest/v1/logout';
  static CHANGE_PASSWORD: string = RestApiRequest.getRootUrl() + '/rest/v1/changePassword';
  static EXERCISES: string = RestApiRequest.getRootUrl() + '/rest/v1/exercises';
  static CREATE_SESSION: string =
    RestApiRequest.getRootUrl() + '/rest/v1/sessions/create/:exerciseId';
  static GET_SESSION_INFO: string = RestApiRequest.getRootUrl() + '/rest/v1/sessions/:sessionId';
  static GET_SESSIONS_INFO: string = RestApiRequest.getRootUrl() + '/rest/v1/sessions/info';
  static JOIN_SESSION: string = RestApiRequest.getRootUrl() + '/rest/v1/sessions/:sessionId/join';
  static LEAVE_SESSION: string = RestApiRequest.getRootUrl() + '/rest/v1/sessions/:sessionId/leave';
  static TERMINATE_SESSION: string = RestApiRequest.getRootUrl() + '/rest/v1/sessions/:sessionId';
  static SIMTIMES_SESSION: string =
    RestApiRequest.getRootUrl() + '/rest/v1/sessions/:sessionId/times';
  static GET_WORKINGPOSITION_URL: string =
    RestApiRequest.getRootUrl() + '/rest/v1/sessions/:sessionId/workingPositionUrl';
  static START_SESSION: string =
    RestApiRequest.getRootUrl() + '/rest/v1/sessions/:sessionId/start?start=:start';
  static READY_FOR_SESSION: string =
    RestApiRequest.getRootUrl() + '/rest/v1/sessions/:sessionId/ready?ready=:ready';
  static WORKSPACE: string =
    RestApiRequest.getRootUrl() + '/rest/v1/sessions/:sessionId/workspaces';
  static DASHBOARD: string = RestApiRequest.getRootUrl() + '/rest/v1/dashboard';

  static async login(
    data: Credentials,
    success: (auth: Authentication) => void,
    failure: (msg: string) => void,
  ) {
    try {
      const response: Response = await fetch(RestApiRequest.LOGIN, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        return failure(await RestApiRequest.extractErrorMessage(response));
      }
      const authResponse: Authentication = (await response.json()) as Authentication;
      success(authResponse);
    } catch (e) {
      failure(RestApiRequest.getErrorMessage(e));
    }
  }

  static async changePassword(
    passwordChange: PasswordChange,
    csrfToken: string,
    success: (answer: string) => void,
    failure: (msg: string) => void,
  ): Promise<void> {
    await fetch(RestApiRequest.CHANGE_PASSWORD + '?csrfToken=' + csrfToken, {
      method: 'POST',
      body: JSON.stringify(passwordChange),
      headers: {
        'Content-Type': 'application/json',
        'Csrf-Token': csrfToken,
      },
    }).then(async (response) => {
      if (response.ok) {
        return success('password changed');
      }

      if (response.status === 401 || response.status > 500) {
        throw new HttpError(response.status);
      }

      return failure(await RestApiRequest.extractErrorMessage(response));
    });
  }

  static async logout() {
    try {
      const response = await fetch(RestApiRequest.LOGOUT, {
        method: 'POST',
      });
      if (response.ok || response.status !== 303) {
        return;
      }
      console.log(await RestApiRequest.extractErrorMessage(response));
    } catch (e) {
      console.log(e);
    }
  }

  static async exercises(csrfToken: string, success: (exercises: ExerciseInfo[]) => void) {
    const response = await fetch(RestApiRequest.EXERCISES, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Csrf-Token': csrfToken,
      },
    });

    if (!response.ok) {
      throw new HttpError(response.status, await RestApiRequest.extractErrorMessage(response));
    }
    const exercisesResponse = (await response.json()) as ExerciseList;
    success(exercisesResponse.exercises);
  }

  /**
   * Create a new simulator session.
   * @param exerciseId : number  exerciseId: number from the restApiRequest !!!!
   * @param csrfToken : string
   * @return Promise<string> : Promise containing the id of the created session
   * @throws HttpError
   */
  static async createSession(exerciseId: number, csrfToken: string): Promise<string> {
    const response: Response = await fetch(
      RestApiRequest.CREATE_SESSION.replace(':exerciseId', String(exerciseId)),
      {
        method: 'POST',
        body: JSON.stringify(exerciseId),
        headers: {
          'Content-Type': 'application/json',
          'Csrf-Token': csrfToken,
        },
      },
    );

    if (response.ok) {
      const createSessionResponse: SessionInfo = (await response.json()) as SessionInfo;
      return createSessionResponse.id;
    }

    if (
      response.status === 400 &&
      response.headers.get('content-type')?.toLowerCase() === 'application/json'
    ) {
      const json = await response.json();
      const message = json['message'];
      throw new HttpError(response.status, message, json);
    }

    throw new HttpError(response.status, await RestApiRequest.extractErrorMessage(response));
  }

  /**
   * Join a simulator session.
   * @param sessionId : string The session ID.
   * @param csrfToken : string
   * @return Promise<string> : Promise with the id of the joined session
   * @throws HttpError
   */
  static async joinSession(sessionId: string, csrfToken: string) {
    const response: Response = await fetch(
      RestApiRequest.JOIN_SESSION.replace(':sessionId', sessionId),
      {
        method: 'POST',
        body: JSON.stringify(sessionId),
        headers: {
          'Content-Type': 'application/json',
          'Csrf-Token': csrfToken,
        },
      },
    );

    if (response.ok) {
      const responseSessionId: string = (await response.json()) as string;
      return responseSessionId;
    }

    if (
      response.status === 400 &&
      response.headers.get('content-type')?.toLowerCase() === 'application/json'
    ) {
      const json = await response.json();
      const message = json['message'];
      throw new HttpError(response.status, message, json);
    }
    throw new HttpError(response.status, await RestApiRequest.extractErrorMessage(response));
  }

  /**
   * Leave a simulator session. "Fire and forget", only will error on log out/connection loss
   * @param sessionId : string The session ID.
   * @param csrfToken : string
   * @throws HttpError only on HTTP status 401
   */
  static async leaveSession(sessionId: string, csrfToken: string): Promise<void> {
    const response: Response = await fetch(
      RestApiRequest.LEAVE_SESSION.replace(':sessionId', sessionId),
      {
        method: 'POST',
        body: JSON.stringify(sessionId),
        headers: {
          'Content-Type': 'application/json',
          'Csrf-Token': csrfToken,
        },
      },
    );
    if (response.status === 401 || response.status > 500) {
      throw new HttpError(response.status, await RestApiRequest.extractErrorMessage(response));
    }
  }

  /**
   * Leave a simulator session.
   * @param sessionId : string The session ID.
   * @param csrfToken : string
   * @throws HttpError only on HTTP status 401
   */
  static async terminateSession(sessionId: string, csrfToken: string) {
    const response: Response = await fetch(
      RestApiRequest.TERMINATE_SESSION.replace(':sessionId', sessionId),
      {
        method: 'DELETE',
        headers: {
          'Csrf-Token': csrfToken,
        },
      },
    );
    if (response.ok) {
      return;
    }
    throw new HttpError(response.status, await RestApiRequest.extractErrorMessage(response));
  }

  static async getSimulationTimes(sessionId: string, csrfToken: string): Promise<SimulationTimes> {
    const response = await fetch(RestApiRequest.SIMTIMES_SESSION.replace(':sessionId', sessionId), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Csrf-Token': csrfToken,
      },
    });

    if (!response.ok) {
      throw new HttpError(response.status, await RestApiRequest.extractErrorMessage(response));
    }

    const simTimes: SimulationTimes = (await response.json()) as SimulationTimes;
    return simTimes;
  }

  /**
   * Change the 'ready' state of a user for a simulator session.
   * @param sessionId : string The session ID.
   * @param ready : boolean set true for ready
   * @param csrfToken : string
   * @throws HttpError
   */
  static async changeUserReadyForSession(sessionId: string, ready: boolean, csrfToken: string) {
    const response: Response = await fetch(
      RestApiRequest.READY_FOR_SESSION.replace(':sessionId', sessionId).replace(
        ':ready',
        String(ready),
      ),
      {
        method: 'PATCH',
        headers: {
          'Csrf-Token': csrfToken,
        },
      },
    );

    if (response.ok) {
      return;
    }
    console.warn(
      `Failure while setting user ready state for session`,
      response.status,
      response.statusText,
    );
    throw new HttpError(response.status, await RestApiRequest.extractErrorMessage(response));
  }

  /**
   * Start the session with the given ID
   * @param sessionId : string The session ID.
   * @param start : boolean set true to start
   * @param csrfToken : string
   * @throws HttpError
   */
  static async startSession(sessionId: string, start: boolean, csrfToken: string) {
    const response: Response = await fetch(
      RestApiRequest.START_SESSION.replace(':sessionId', sessionId).replace(
        ':start',
        String(start),
      ),
      {
        method: 'PATCH',
        headers: {
          'Csrf-Token': csrfToken,
        },
      },
    );

    if (response.ok) {
      return;
    }
    console.warn(`Failure while starting session`, response.status, response.statusText);
    throw new HttpError(response.status, await RestApiRequest.extractErrorMessage(response));
  }

  /**
   * Get the working position URI for the session with the given ID
   * @param sessionId : string The session ID.
   * @param csrfToken : string
   * @throws HttpError
   */
  static async getWorkingPositionUri(
    sessionId: string,
    csrfToken: string,
  ): Promise<boolean | string> {
    const response: Response = await fetch(
      RestApiRequest.GET_WORKINGPOSITION_URL.replace(':sessionId', sessionId),
      {
        method: 'GET',
        headers: {
          'Csrf-Token': csrfToken,
        },
      },
    );

    if (!response.ok) {
      console.warn(`Failure while starting session`, response.status, response.statusText);
      throw new HttpError(response.status, await RestApiRequest.extractErrorMessage(response));
    }
    return (await response.json())['workingPositionUrl'];
  }

  /**
   * Get list of session infos.
   * @param csrfToken : string
   * @param success : function(session:SessionInfo[]):void Callback on success
   * @throws HttpError
   */
  static async getAllSessionInfo(csrfToken: string, success: (session: SessionInfo[]) => void) {
    const response: Response = await fetch(RestApiRequest.GET_SESSIONS_INFO, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Csrf-Token': csrfToken,
      },
    });

    if (!response.ok) {
      throw new HttpError(response.status, response.statusText);
    }
    const reply: SessionInfo[] = (await response.json()) as SessionInfo[];
    success(reply);
  }

  /**
   * Get session infos for a specific session.
   * @param csrfToken : string
   * @param sessionId : string
   * @throws HttpError
   */
  static async getSessionInfo(csrfToken: string, sessionId: string) {
    const response: Response = await fetch(
      RestApiRequest.GET_SESSION_INFO.replace(':sessionId', sessionId),
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Csrf-Token': csrfToken,
        },
      },
    );

    if (!response.ok) {
      throw new HttpError(response.status, await RestApiRequest.extractErrorMessage(response));
    }
    const sessionInfo: SessionInfo = (await response.json()) as SessionInfo;
    return sessionInfo;
  }

  /**
   * Get workspaces of specific session.
   * @param sessionId : string The session ID.
   * @param csrfToken : string
   * @param success : function(workspaceAssignmentEvent: "WorkspaceAssignmentsEvent"):void Callback on success
   * @throws HttpError
   */
  static async getWorkspaceList(
    sessionId: string,
    csrfToken: string,
    success: (workspaceAssignmentEvent: WorkspaceAssignmentEvent) => void,
  ): Promise<void> {
    const response = await fetch(RestApiRequest.WORKSPACE.replace(':sessionId', sessionId), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Csrf-Token': csrfToken,
      },
    });

    if (!response.ok) {
      throw new HttpError(response.status, await RestApiRequest.extractErrorMessage(response));
    }
    const reply: WorkspaceAssignmentEvent = (await response.json()) as WorkspaceAssignmentEvent;
    success(reply);
  }

  /**
   * Select workspace of specific session.
   * @param sessionId : string The session ID.
   * @param csrfToken : string
   * @param workspaceAssignmentCommand : WorkspaceAssignment
   * @param success : function(workspaceAssignmentEvent: "WorkspaceAssignmentsEvent"):void Callback on success
   * @throws HttpError
   */
  static async selectWorkspace(
    sessionId: string,
    csrfToken: string,
    workspaceAssignmentCommand: WorkspaceAssignmentCommand,
    success: (workspaceAssignmentEvent: WorkspaceAssignmentEvent) => void,
  ): Promise<void> {
    const response = await fetch(RestApiRequest.WORKSPACE.replace(':sessionId', sessionId), {
      method: 'POST',
      body: JSON.stringify(workspaceAssignmentCommand),
      headers: {
        'Content-Type': 'application/json',
        'Csrf-Token': csrfToken,
      },
    });
    if (!response.ok) {
      throw new HttpError(response.status, await RestApiRequest.extractErrorMessage(response));
    }
    const reply: WorkspaceAssignmentEvent = (await response.json()) as WorkspaceAssignmentEvent;
    success(reply);
  }

  static async getDashboard(
    csrfToken: string,
    success: (dashboardInfo: DashboardInfo) => void,
  ): Promise<void> {
    const response = await fetch(RestApiRequest.DASHBOARD, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Csrf-Token': csrfToken,
      },
    });

    if (!response.ok) {
      throw new HttpError(response.status, await RestApiRequest.extractErrorMessage(response));
    }
    const reply: DashboardInfo = (await response.json()) as DashboardInfo;
    success(reply);
  }

  static async extractErrorMessage(response: Response) {
    if (response.headers.get('content-type')?.toLowerCase() === 'application/json') {
      return (
        (await response.json())['message'] || RestApiRequest.httpStatusToMessage(response.status)
      );
    }

    if (response.status === 503 || response.status === 504) {
      return RestApiRequest.httpStatusToMessage(response.status);
    }

    if (response.headers.get('content-type')?.toLowerCase() === 'text/plain') {
      return (await response.text()) || RestApiRequest.httpStatusToMessage(response.status);
    }
    return RestApiRequest.httpStatusToMessage(response.status);
  }

  static getErrorMessage(error: unknown): string {
    if (error instanceof Error) {
      return error.message;
    } else if (error instanceof Object) {
      return error.toString();
    } else if (typeof error == 'string') {
      return error;
    } else {
      return 'Unknown error type: ' + typeof error;
    }
  }

  static httpStatusToMessage(status: number) {
    switch (status) {
      case 400:
        return 'Bad Request';
      case 401:
        return 'Unauthorized';
      case 403:
        return 'Forbidden';
      case 404:
        return 'Not Found';
      case 405:
        return 'Method Not Allowed';
      case 408:
        return 'Request Timeout';
      case 500:
        return 'Internal Server Error';
      // fall through to 503 is intentional, 'Service Unavailable' should be more meaningful to the end user than 'Gateway Timeout'
      case 502:
      case 503:
      case 504:
        return 'Service Unavailable';
      default:
        return `Error, HTTP Status: ${status}`;
    }
  }
}

export { RestApiRequest };
