<script setup lang="ts">
import { useLoginStore } from '@/stores/LoginStore';
import { useRoute, useRouter } from 'vue-router';
import { onBeforeMount, onUnmounted, type Ref, ref, watch } from 'vue';
import { RestApiRequest } from '@/restApiRequest';
import type { WorkspaceAssignment } from '@/model/WorkspaceAssignment';
import type { WorkspaceAssignmentEvent } from '@/model/WorkspaceAssignmentEvent';
import { ScheduledCall } from '@/connection/ScheduledCall';
import { useLeaveLobby } from '@/components/lobby/LeaveLobby';
import ExerciseLobbyControls from '@/components/lobby/ExerciseLobbyControls.vue';
import ParticipantList from '@/components/participants/ParticipantList.vue';
import type { SimulatorSessionState } from '@/model/SimulatorSessionState';
import ExerciseLobbyWorkspaces from '@/components/lobby/ExerciseLobbyWorkspaces.vue';
import { FetchSessionInfoLobby } from '@/components/helper/FetchSessionInfoLobby';
import { HttpError } from '@/connection/HttpError';
import {
  sessionLeaveDialog,
  sessionLostDialog,
  sessionStartDialog,
} from '@/components/dialogs/dialogs';
import { readinessToBannerText } from '../components/lobby/SessionProgressUtil.ts';

const OVERVIEW_PAGE = {
  name: 'overview',
};

const loginStore = useLoginStore();
const route = useRoute();
const router = useRouter();

const sessionId = Array.isArray(route.params.sessionId)
  ? route.params.sessionId[0]
  : route.params.sessionId;

const workspaces: Ref<WorkspaceAssignment[]> = ref([]);
const workspaceSelected = ref(false);
const fetchSessionInfo = new FetchSessionInfoLobby(
  sessionId,
  loginStore.csrfToken,
  loginStore.userName,
);

const {
  participants,
  isHost,
  sessionError,
  sessionInfoScheduler,
  isLoading,
  currentUserReady,
  sessionReadiness,
  sessionStatus,
  userInSession,
  sessionErrorMsg,
} = fetchSessionInfo.get();

const { leaveLobbyAction, terminationConfirmed } = useLeaveLobby(
  sessionId,
  isHost,
  sessionError,
  sessionErrorMsg,
);

const workspaceListScheduler = new ScheduledCall<void>('WorkspaceList', 500, getWorkspaceList);

const sessionLostDialogOpen = ref(false);

function showSessionLostDialog(error: HttpError | undefined) {
  if (!error || sessionLostDialogOpen.value) {
    return;
  }
  sessionInfoScheduler.stop();
  workspaceListScheduler.stop();
  if (isHost.value && terminationConfirmed.value) {
    return;
  }
  if (error.httpStatus !== 400 && error.httpStatus !== 404) {
    throw error;
  }
  sessionLostDialogOpen.value = true;

  sessionLostDialog().onOk(() => {
    sessionLostDialogOpen.value = false;
    terminationConfirmed.value = true;
    router.push(OVERVIEW_PAGE);
  });
}

let openWorkingPositionClicked = false;

watch(sessionStatus, watchSessionState, { immediate: true });
watch(sessionError, showSessionLostDialog, { immediate: true });
watch(userInSession, userLeftSession);

async function userLeftSession() {
  if (userInSession.value) {
    return;
  }
  await leaveLobbyAction();
}

async function getWorkspaceList() {
  if (sessionError.value) {
    workspaceListScheduler.stop();
    sessionInfoScheduler.stop();
    return;
  }
  try {
    await RestApiRequest.getWorkspaceList(sessionId, loginStore.csrfToken, setWorkspaceList);
    return;
  } catch (error) {
    if (error instanceof HttpError) {
      onError(error);
      return;
    }
    throw error;
  }
}

async function startSessionAction() {
  if (!isHost.value || openWorkingPositionClicked) {
    return;
  }
  try {
    openWorkingPositionClicked = true;
    await RestApiRequest.startSession(sessionId, true, loginStore.csrfToken);
    const workingPositionUrl = router.resolve({ name: 'working_position', params: { sessionId } });
    router
      .push({
        name: 'backstage',
        params: { sessionId: sessionId },
      })
      .finally(() => {
        window.open(workingPositionUrl.href, '_blank');
      });
  } catch (error) {
    if (error instanceof HttpError) {
      onError(error);
      return;
    }
    throw error;
  }
}

async function readyAction(ready: boolean) {
  try {
    await RestApiRequest.changeUserReadyForSession(sessionId, ready, loginStore.csrfToken);
  } catch (error) {
    if (error instanceof HttpError) {
      onError(error);
      return;
    }
    throw error;
  }
}

const onLeave = () => {
  sessionLeaveDialog().onOk(() => {
    leaveLobbyAction();
  });
};

function onError(error: HttpError) {
  sessionInfoScheduler.stop();
  workspaceListScheduler.stop();
  sessionError.value = error;
}

function onSessionError() {
  leaveLobbyAction();
}

async function watchSessionState(newState: SimulatorSessionState) {
  if ('ERROR' === newState) {
    onSessionError();
  }

  if (
    sessionError.value ||
    openWorkingPositionClicked ||
    !('RUNNING' === newState || 'PAUSE' === newState || 'STARTING' === newState)
  ) {
    return;
  }

  if (isHost.value) {
    return;
  }
  const workingPositionUrl = router.resolve({ name: 'working_position', params: { sessionId } });

  sessionStartDialog().onOk(() => {
    openWorkingPositionClicked = true;
    router
      .push({
        name: 'backstage',
        params: { sessionId: sessionId },
      })
      .finally(() => {});
    window.open(workingPositionUrl.href, '_blank');
  });
}

const setWorkspaceList = (workspaceAssignmentEvent: WorkspaceAssignmentEvent) => {
  workspaces.value = workspaceAssignmentEvent.entries;

  workspaces.value.forEach((workspace) => {
    if (workspace.assignee === loginStore.userName) {
      workspace.assignable = true;
      workspaceSelected.value = workspace.selected;
    }
  });
};

const selectWorkspace = async (id: number, selected: boolean, vrrSelected: boolean) => {
  if (currentUserReady.value) {
    return;
  }
  console.debug(`selectWorkspace: ${id}, selected=${selected}, vrrSelected=${vrrSelected}`);

  const workspace = workspaces.value.find((workspace) => workspace.id === id);
  if (!workspace) {
    return;
  }
  // Important: we make a copy here!
  const workspaceAssignmentCommand = {
    id: workspace.id,
    assignee: loginStore.userName,
    selected: selected,
    vrrSelected: vrrSelected,
  };
  try {
    await RestApiRequest.selectWorkspace(
      sessionId,
      loginStore.csrfToken,
      workspaceAssignmentCommand,
      setWorkspaceList,
    );
  } catch (error) {
    if (error instanceof HttpError) {
      sessionError.value = error;
    }
    throw error;
  }
  workspaceSelected.value = selected;
};

onBeforeMount(() => {
  sessionInfoScheduler.start();
  workspaceListScheduler.start();
});

onUnmounted(() => {
  sessionInfoScheduler.stop();
  workspaceListScheduler.stop();
});
</script>

<template>
  <q-page>
    <template v-if="workspaces.length > 0 && !isLoading">
      <q-banner class="bg-secondary text-white" dense data-testid="lobby_banner">
        <span style="font-weight: bold">
          {{ readinessToBannerText(sessionReadiness, isHost) }}
        </span>
      </q-banner>
      <div class="fit row justify-between q-pa-md" style="width: 100vh">
        <div class="col-xs-auto col-lg-8 q-pa-sm">
          <q-separator spaced style="background: none; color: white" />
          <ExerciseLobbyWorkspaces
            :workspaces="workspaces"
            :disable="currentUserReady"
            @select-workspace="selectWorkspace"
          />
          <ExerciseLobbyControls
            :is-host="isHost"
            :workspace-selected="workspaceSelected"
            :current-user-ready="currentUserReady"
            :session-readiness="sessionReadiness"
            @ready="readyAction(!currentUserReady)"
            @start="startSessionAction"
            @leave="onLeave"
            @terminate="leaveLobbyAction"
          />
        </div>
        <div class="col-xs-12 col-lg-4 q-pa-sm" style="min-width: 20em; max-width: 20em">
          <participant-list :participants="participants" />
        </div>
      </div>
    </template>
  </q-page>
</template>

<style scoped lang="sass" />
