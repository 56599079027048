import type { SimulatorSessionState } from '@/model/SimulatorSessionState';
import type { SessionInfo } from '@/model/SessionInfo';
import { isUserInSession } from '@/components/participants/Participants';
import type { SimulationMode } from '@/enums/SimulationMode';

interface ExerciseSessionData {
  id: string;
  ownerId: string;
  exerciseName: string;
  description: string;
  simulationMode: SimulationMode;
  hasVrr: boolean;
  sessionState: SimulatorSessionState;
  sessionUsers: string[];
}

const getExerciseSessionData = (sessions: SessionInfo[]): ExerciseSessionData[] => {
  return Object.entries(sessions).map(([, v]) => extractFromSession(v));
};

const extractFromSession = (session: SessionInfo): ExerciseSessionData => {
  const exerciseSessionData: ExerciseSessionData = {
    id: session.id,
    ownerId: session.ownerId,
    exerciseName: session.exercise.name,
    description: session.exercise.description,
    simulationMode: session.exercise.simulationMode as SimulationMode,
    hasVrr: session.exercise.hasVrr,
    sessionState: session.sessionState as SimulatorSessionState,
    sessionUsers: getSessionUsers(session),
  };
  return exerciseSessionData;
};

const getSessionUsers = (session: SessionInfo) => {
  return Object.entries(session.participantInfo)
    .filter(([, v]) => isUserInSession(v.status))
    .map(([, v]) => v.userDisplayName);
};

export { type ExerciseSessionData, getExerciseSessionData };
