import { type Ref, ref } from 'vue';
import { mapSessionState, type SimulatorSessionState } from '@/model/SimulatorSessionState';
import type { SessionInfo } from '@/model/SessionInfo';
import type { SessionReadiness } from '@/model/SessionReadiness';
import { FetchSessionInfo } from '@/components/helper/FetchSessionInfo';

/**
 * Class to handle the periodic data fetching and parsing for a simulator session in lobby.
 *
 * @param sessionId : string session id to fetch data for
 * @param csrfToken : string the current CSRF token
 * @param userName : string the current username
 *
 * @returns participants : Ref<Participants> the sessions participants
 * @returns isLoading : Ref<boolean> false once the composable has received the first successful response from the backend
 * @returns isHost : Ref<boolean> true if the current user is the host of the session
 * @returns userIsInSession : Ref<boolean> true if the current user is a participant of the running session (or host)
 * @returns currentUserReady : Ref<boolean> true if the current user is 'ready'
 * @returns sessionNotFound : Ref<boolean> true if the backend responded with HTTP status 404 for the sessionId
 * @returns sessionReadiness : Ref<SessionReadiness> the current session readiness
 * @returns sessionInfoScheduler : ScheduledCall<void> reference to the scheduler polling the backend, use to stop the scheduler on page unload
 * @returns sessionStatus : Ref<SimulatorSessionState> the current session status
 */
export class FetchSessionInfoLobby extends FetchSessionInfo {
  private readonly _isLoading = ref(true);
  private readonly _currentUserReady = ref(false);
  private readonly _sessionReadiness = ref('WAITING_FOR_BACKEND') as Ref<SessionReadiness>;
  private readonly _sessionStatus = ref('SETUP') as Ref<SimulatorSessionState>;

  constructor(sessionId: string, csrfToken: string, userName: string) {
    super(sessionId, csrfToken, userName);
    this.getSessionInfo().finally(() => (this._isLoading.value = false));
  }

  async getSessionInfo() {
    const sessionInfo = await super.getSessionInfo();
    if (!sessionInfo) {
      return;
    }
    this.checkCurrentUserReady(sessionInfo);
    this._sessionStatus.value = sessionInfo.sessionState;
    this._sessionReadiness.value = mapSessionState(
      sessionInfo.sessionState,
      sessionInfo.allParticipantsReady,
    );
    return sessionInfo;
  }

  checkCurrentUserReady(sessionInfo: SessionInfo) {
    for (const user in sessionInfo.participantInfo) {
      if (user === this._userName) {
        this._currentUserReady.value = sessionInfo.participantInfo[user].status === 'READY';
      }
    }
  }

  get() {
    return {
      participants: this.participants,
      isHost: this.isHost,
      userInSession: this.userIsInSession,
      sessionError: this.sessionError,
      sessionInfoScheduler: this.sessionInfoScheduler,
      isLoading: this._isLoading,
      currentUserReady: this._currentUserReady,
      sessionReadiness: this._sessionReadiness,
      sessionStatus: this._sessionStatus,
      sessionErrorMsg: this._sessionErrorMsg,
    };
  }
}
