import { useLoginStore } from '@/stores/LoginStore';
import { onBeforeRouteLeave, type RouteLocationNormalizedLoadedGeneric } from 'vue-router';
import { type Ref, ref } from 'vue';
import { RestApiRequest } from '@/restApiRequest';
import router from '@/router';
import type { HttpError } from '@/connection/HttpError';
import {
  sessionErrorAndTerminateDialog,
  terminateSessionDialog,
} from '@/components/dialogs/dialogs';

/**
 * Composable to leave the lobby. Returns 'async function leaveLobbyAction()'. Call this to leave the lobby and route to the 'Overview' page.
 *
 * The composable listens to 'onBeforeRouteLeave' and intercepts the vue routing to interact with the user when navigating away from the lobby.
 *
 * If the user is the session host, switching to another view has to be confirmed, on confirmation the session is terminated.
 *
 * Joined users may navigate away without confirmation, the composable then ensures that the user leaves the session/
 *
 * @param sessionId : string session id of the lobby instance
 * @param isHost : Ref<boolean> reactive ref determining the host
 * @param sessionNotFound : Ref<boolean> reactive ref that should change to 'false' if the session is lost
 */
export function useLeaveLobby(
  sessionId: string,
  isHost: Ref<boolean>,
  sessionError: Ref<undefined | HttpError>,
  sessionErrorMsg: Ref<string>,
) {
  const OVERVIEW_PAGE = {
    name: 'overview',
  };

  const loginStore = useLoginStore();

  const terminationConfirmed = ref(false);
  const interceptedTo = ref() as Ref<RouteLocationNormalizedLoadedGeneric>;

  onBeforeRouteLeave(async (to) => {
    interceptedTo.value = to;
    if (to.name?.toString() === 'backstage') {
      return true;
    }
    if (!isHost.value) {
      await RestApiRequest.leaveSession(sessionId, loginStore.csrfToken);
      return true;
    }
    if (!terminationConfirmed.value) {
      showTerminateSessionDialog();
      return false;
    }
    if (to.name?.toString() === 'login_view') {
      loginStore.logout();
    }
    return true;
  });

  async function leaveLobbyAction() {
    if (sessionErrorMsg.value.length > 0 && isHost.value) {
      showSessionErrorAndTerminateDialog();
      return;
    }
    if (sessionErrorMsg.value.length > 0) {
      return;
    }
    if (isHost.value) {
      showTerminateSessionDialog();
      return;
    }
    await router.push(OVERVIEW_PAGE);
  }

  async function terminateSession() {
    terminationConfirmed.value = true;
    console.debug(`terminating session. sessingId=${sessionId}`);
    await RestApiRequest.terminateSession(sessionId, loginStore.csrfToken);
    await router.push(interceptedTo.value || OVERVIEW_PAGE);
  }

  function showTerminateSessionDialog() {
    if (sessionError.value) {
      return;
    }
    terminateSessionDialog().onOk(terminateSession);
  }

  function showSessionErrorAndTerminateDialog() {
    if (sessionError.value) {
      return;
    }
    sessionErrorAndTerminateDialog(sessionId, sessionErrorMsg.value).onOk(terminateSession);
  }

  return { leaveLobbyAction, terminationConfirmed };
}
