<script setup lang="ts">
import { toRefs } from 'vue';
import type { SessionReadiness } from '@/model/SessionReadiness';
import { readinessToStartButtonTooltipText } from '@/components/lobby/SessionProgressUtil.ts';

interface Props {
  isHost: boolean;
  workspaceSelected: boolean;
  currentUserReady: boolean;
  sessionReadiness: SessionReadiness;
}

const props = defineProps<Props>();
const { isHost, workspaceSelected, currentUserReady, sessionReadiness } = toRefs(props);

defineEmits(['ready', 'requestUser', 'start', 'leave', 'terminate']);

function readyButtonDisabled() {
  return (
    sessionReadiness.value === 'STARTED' ||
    sessionReadiness.value === 'STARTING' ||
    sessionReadiness.value === 'ERROR' ||
    !workspaceSelected.value
  );
}
</script>

<template>
  <div class="row q-mt-md q-gutter-md">
    <q-btn
      class="btn-fixed-width-l"
      color="secondary"
      :disable="readyButtonDisabled()"
      :label="currentUserReady ? 'Not Ready' : 'Ready'"
      @click="$emit('ready')"
    />
    <q-btn
      style="display: none"
      class="btn-fixed-width-l"
      color="secondary"
      label="Request User"
      @click="$emit('requestUser')"
    />
    <q-btn
      v-if="isHost"
      :disable="sessionReadiness !== 'READY'"
      class="btn-fixed-width-l"
      color="secondary"
      label="Start"
      :loading="sessionReadiness === 'WAITING_FOR_BACKEND'"
      @click="$emit('start')"
    >
      <q-tooltip>{{ readinessToStartButtonTooltipText(sessionReadiness) }}</q-tooltip>
    </q-btn>
    <q-btn
      class="btn-fixed-width-l"
      color="secondary"
      :label="isHost ? 'Terminate Session' : 'Leave'"
      @click="isHost ? $emit('terminate') : $emit('leave')"
    />
  </div>
</template>
