import { Dialog } from 'quasar';
import CustomOkDialog from '@/components/dialogs/CustomOkDialog.vue';
import CustomOkCancelDialog from '@/components/dialogs/CustomOkCancelDialog.vue';

export const infoDialog = (title: string | undefined, message: string | undefined) => {
  return Dialog.create({
    component: CustomOkDialog,
    componentProps: {
      title: title || 'Info',
      message: message,
      iconType: 'info',
    },
  });
};

export const warningDialog = (title: string | undefined, message: string | undefined) => {
  return Dialog.create({
    component: CustomOkDialog,
    componentProps: {
      title: title || 'Warning',
      message: message,
      iconType: 'warning',
      colorType: 'warning',
    },
  });
};

export const errorDialog = (error: unknown | string | Error) => {
  let title: string = 'Error';
  let message: string = 'Unspecified error!';
  if (error instanceof Error) {
    title = error?.name;
    if (error.message) {
      message = error.message;
    }
  }
  if (typeof error === 'string') {
    message = error;
  }

  return Dialog.create({
    component: CustomOkDialog,
    componentProps: {
      title: title,
      message: message,
      iconType: 'error',
      colorType: 'negative',
    },
  });
};

// ----------------------------------------------------------------------------

export const terminateSessionDialog = () => {
  return Dialog.create({
    component: CustomOkCancelDialog,
    componentProps: {
      title: 'Terminate the session?',
      message: 'This will end the session for everyone!',
      okLabel: 'Yes',
      cancelLabel: 'No',
      iconType: 'cancel',
      focus: 'cancel',
    },
  });
};

export const deleteSessionDialog = (sessionId: string) => {
  return Dialog.create({
    component: CustomOkCancelDialog,
    componentProps: {
      title: 'Terminate the session?',
      message: `This will end the session for everyone! Session id is: ${sessionId}`,
      okLabel: 'Yes',
      cancelLabel: 'No',
      iconType: 'cancel',
      focus: 'cancel',
    },
  });
};

export const sessionLostDialog = () => {
  return Dialog.create({
    component: CustomOkDialog,
    componentProps: {
      title: 'The session was terminated!',
      message: 'The session was terminated. Return to "Overview"!',
      okLabel: 'Ok',
      iconType: 'warning',
      colorType: 'warning',
    },
  });
};

export const sessionErrorDialog = () => {
  return Dialog.create({
    component: CustomOkDialog,
    componentProps: {
      title: 'ERROR! Failed to connect to the session!',
      message: 'Likely causes: the session was terminated or the system encountered a problem',
      okLabel: 'Close Tab/Window',
      iconType: 'error',
      colorType: 'negative',
    },
  });
};

export const sessionErrorAndTerminateDialog = (sessionId: string, errorMsg: string) => {
  return Dialog.create({
    component: CustomOkDialog,
    componentProps: {
      title: `Error in session ( ${sessionId} ) startup `,
      message: `${errorMsg}`,
      okLabel: 'Terminate',
      iconType: 'error',
      colorType: 'negative',
    },
  });
};

export const sessionStartDialog = () => {
  return Dialog.create({
    component: CustomOkDialog,
    componentProps: {
      title: 'Session is starting!',
      message: "The session is starting, click 'OK' to go to the working position",
      okLabel: 'Ok',
      iconType: 'info',
    },
  });
};

export const sessionLeaveDialog = () => {
  return Dialog.create({
    component: CustomOkCancelDialog,
    componentProps: {
      title: 'Leave the session?',
      message: 'You might not be able to join the session again! Return to "Overview"?',
      okLabel: 'Yes',
      cancelLabel: 'No',
      iconType: 'cancel',
      focus: 'cancel',
    },
  });
};

export const multipleSessionsDialog = () => {
  return Dialog.create({
    component: CustomOkCancelDialog,
    componentProps: {
      title: 'Only one Simulator Session allowed',
      message:
        'You can only participate in one session at a time. Please finish or leave your current session. Re-open your session?',
      okLabel: 'Yes',
      cancelLabel: 'No',
      iconType: 'warning',
      colorType: 'warning',
    },
  });
};

export const enableVrrDialog = () => {
  return Dialog.create({
    component: CustomOkCancelDialog,
    componentProps: {
      title: 'Enable VRR?',
      message: 'Do you want to enable VRR?',
      okLabel: 'Yes',
      cancelLabel: 'No',
      iconType: 'record_voice_over',
    },
  });
};
