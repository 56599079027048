export interface AvailableListFilters {
  selectedSimModes: string[];
  filterText: string;
  vrrFilterOn: boolean;
}

export interface ActiveListFilters {
  onlyRunningExercises: boolean;
  filterText: string;
}

const setAvailableListFilters = (
  selectedSimModes: string[],
  text: string,
  vrrFilterOn: boolean,
): AvailableListFilters => {
  return {
    selectedSimModes: selectedSimModes,
    filterText: text,
    vrrFilterOn: vrrFilterOn,
  };
};

const setActiveListFilters = (onlyRunningEx: boolean, text: string): ActiveListFilters => {
  return {
    onlyRunningExercises: onlyRunningEx,
    filterText: text,
  };
};

export { setAvailableListFilters, setActiveListFilters };
