import type { WorkspaceAssignment } from '@/model/WorkspaceAssignment';
import { VrrState } from '@/enums/VrrState';
import { PositionType } from '@/components/participants/Participants';

const getIconByPositionType = (positionType: string): string => {
  const type: string = positionType.toLowerCase();
  return type in PositionType ? PositionType[type as keyof typeof PositionType] : PositionType.none;
};

function filterWorkspaces(
  workspaces: WorkspaceAssignment[],
  filter: string,
  vrrOnly: boolean = false,
  filterPosTypes: PositionType[],
): WorkspaceAssignment[] {
  return workspaces
    .filter((workspace: WorkspaceAssignment) => {
      if (filter === '' && !vrrOnly) {
        return true;
      }
      if (filter === '' && vrrOnly) {
        return isVrrPossible(workspace.vrrState);
      }
      if (filter !== '' && vrrOnly) {
        return workspace.position.match(filter.toUpperCase()) && isVrrPossible(workspace.vrrState);
      } else {
        return (
          workspace.position.match(filter.toUpperCase()) ||
          filterPosTypes.includes(
            PositionType[workspace.positionType.toLowerCase() as keyof typeof PositionType],
          )
        );
      }
    })
    .sort(sortWorkspaces());
}

function sortWorkspaces():
  | ((a: WorkspaceAssignment, b: WorkspaceAssignment) => number)
  | undefined {
  return (a: WorkspaceAssignment, b: WorkspaceAssignment) => a.position.localeCompare(b.position);
}

function isVrrPossible(vrrState: VrrState): boolean {
  return vrrState != VrrState.DISABLED;
}

export { getIconByPositionType, filterWorkspaces, sortWorkspaces, isVrrPossible };
