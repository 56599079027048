<script setup lang="ts">
import type { ExerciseInfo } from '@/model/ExerciseInfo';

const showInfo = defineModel<boolean>('showInfo', { required: true, default: false });
defineProps<{ exerciseInfo: ExerciseInfo }>();
</script>

<template>
  <q-dialog v-model="showInfo" style="width: 500px">
    <q-card>
      <q-bar class="bg-primary">
        <div>Exercise Information</div>
        <q-space />
        <q-btn v-close-popup dense flat icon="close">
          <q-tooltip>Close</q-tooltip>
        </q-btn>
      </q-bar>
      <q-card-section>
        <div class="text-h7 q-py-sm">{{ exerciseInfo.name }}</div>
        <q-separator />

        <q-list bordered class="rounded-borders">
          <q-item class="q-mx-sm">
            <q-item-section thumbnail style="width: 120px">
              <q-item-label>Description:</q-item-label>
            </q-item-section>
            <q-item-section>
              <q-item-label>{{ exerciseInfo.description }}</q-item-label>
            </q-item-section>
          </q-item>
          <q-separator />
          <template v-if="exerciseInfo.startTime.length == 0">
            <q-item class="q-mx-sm">
              <q-item-section thumbnail style="width: 120px">
                <q-item-label>StartTime/Date:</q-item-label>
              </q-item-section>
              <q-item-section>
                <q-item-label>UTC </q-item-label>
              </q-item-section>
            </q-item>
          </template>
          <template v-else>
            <q-item class="q-mx-sm">
              <q-item-section thumbnail style="width: 120px">
                <q-item-label>StartTime/Date:</q-item-label>
              </q-item-section>
              <q-item-section>
                <q-item-label
                  >{{ exerciseInfo.startTime }} / {{ exerciseInfo.startDate }}
                </q-item-label>
              </q-item-section>
            </q-item>
          </template>
          <q-separator />
          <q-item class="q-mx-sm">
            <q-item-section thumbnail style="width: 120px">
              <q-item-label>Duration:</q-item-label>
            </q-item-section>
            <q-item-section>
              <q-item-label>{{ exerciseInfo.duration }} min</q-item-label>
            </q-item-section>
          </q-item>
          <q-separator />
          <q-item class="q-mx-sm items-start">
            <q-item-section thumbnail style="width: 120px">
              <q-item-label>Sectors:</q-item-label>
            </q-item-section>
            <q-item-section>
              <q-item-label>
                {{ exerciseInfo.sectorNames.join(', ') }}
              </q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<style scoped lang="sass">
.css-fix
  white-space: pre-wrap
</style>
