<script setup lang="ts">
interface Props {
  title: string;
  message?: string;
  okLabel?: string;
  iconType?: string;
  colorType?: string;
}

const {
  title,
  message = 'N/A',
  okLabel = 'Ok',
  iconType = 'public',
  colorType = 'primary',
} = defineProps<Props>();
const emit = defineEmits(['ok']);

const onOk = () => {
  emit('ok');
};
</script>

<template>
  <q-dialog persistent no-shake>
    <q-card>
      <q-card-section>
        <q-avatar :icon="iconType" :color="colorType" text-color="white" />
        <span class="text-h6 text-bold q-ml-sm"> {{ title }} </span>
      </q-card-section>
      <q-separator />
      <q-card-section>
        <div>{{ message }}</div>
      </q-card-section>
      <q-separator />
      <q-card-actions align="right">
        <q-btn v-close-popup :label="okLabel" :color="colorType" @click="onOk" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<style scoped lang="sass">
.q-card
  width: 75%
</style>
