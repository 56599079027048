import { BaseAvailableExerciseProvider } from '@/components/overview/BaseAvailableExerciseProvider';
import { RestApiRequest } from '@/restApiRequest';
import type { ExerciseInfo } from '@/model/ExerciseInfo';
import { useLoginStore } from '@/stores/LoginStore';

/**
 * Receive exercise message. Inform listener,
 * messages in reactive member.
 */
class AvailableExerciseProvider extends BaseAvailableExerciseProvider {
  private timer: number | undefined | ReturnType<typeof setInterval> = undefined;
  private readonly timeout: number | undefined;
  private loginStore = useLoginStore();

  /**
   *
   * @param id The ID (name).
   * @param timeout Poll timeout in msec
   */
  constructor(id: string, timeout: number) {
    super(id);
    this.timeout = timeout;
  }

  /**
   * Start polling rest api
   * @override BaseAvailableExerciseProvider::start
   */
  start(): void {
    this.requestData();
    if (this.timer == undefined) {
      this.timer = setInterval(this.requestData.bind(this), this.timeout);
    }
  }

  /**
   * Stop polling the rest api
   * @override BaseExercise::stop
   */
  stop() {
    if (this.timer != undefined) {
      clearInterval(this.timer);
    }
  }

  // timer callback
  private async requestData() {
    const success = this.onData.bind(this);
    const csrfToken = this.loginStore.csrftoken;
    try {
      await RestApiRequest.exercises(csrfToken, success);
    } catch (e) {
      this.stop();
      throw e;
    }
  }

  private onData(exercises: ExerciseInfo[]) {
    this.exerciseInfos = exercises;

    for (const callback of this.exerciseCallbacks.values()) {
      callback(this.exerciseInfos);
    }
  }
}

export { AvailableExerciseProvider };
