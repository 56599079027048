import { createRouter, createWebHistory } from 'vue-router';
import ExerciseOverview from '@/pages/ExerciseOverview.vue';
import ExerciseBackstage from '@/pages/ExerciseBackstage.vue';
import { useLoginStore } from '@/stores/LoginStore';
import LegalNotice from '@/pages/LegalNotice.vue';
import ExerciseLobbyView from '@/pages/ExerciseLobbyView.vue';
import PasswordChange from '@/pages/PasswordChange.vue';
import DashBoard from '@/pages/DashBoard.vue';
import LoginView from '@/pages/LoginView.vue';
import WorkingPosition from '@/pages/WorkingPosition.vue';

const defaultLayoutPages = [
  {
    path: '/exercises',
    name: 'overview',
    component: ExerciseOverview,
    meta: {
      title: 'Overview',
    },
  },
  {
    path: '/lobby/:sessionId/:exerciseName',
    name: 'lobby',
    component: ExerciseLobbyView,
    meta: {
      title: 'Lobby',
    },
  },
  {
    path: '/backstage/:sessionId/:exerciseName',
    name: 'backstage',
    component: ExerciseBackstage,
    meta: {
      title: 'Backstage',
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashBoard,
    meta: {
      title: 'Dashboard',
    },
  },
  {
    path: '/impressum',
    name: 'legal_notice',
    component: LegalNotice,
  },

  {
    path: '/passwordChange',
    name: 'password_change',
    component: PasswordChange,
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      component: () => import('../layouts/DefaultLayout.vue'),
      redirect: '/exercises',
      children: defaultLayoutPages,
    },
    {
      path: '/login',
      component: () => import('../layouts/BlankLayout.vue'),
      children: [
        {
          path: '',
          name: 'login_view',
          component: LoginView,
        },
      ],
    },
    {
      path: '/workingPosition/:sessionId',
      component: () => import('../layouts/BlankLayout.vue'),
      children: [
        {
          path: '',
          name: 'working_position',
          component: WorkingPosition,
        },
      ],
    },
  ],
});

router.beforeEach(async (to) => {
  const loginStore = useLoginStore();
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login', '/impressum'];
  const authRequired = !publicPages.includes(to.path);

  if (authRequired && !loginStore.isLoggedIn) {
    return '/login';
  }
});
export default router;
