/* eslint @typescript-eslint/no-explicit-any: "off" */
export class HttpError extends Error {
  readonly httpStatus: number;
  readonly jsonBody: any;

  constructor(status: number, message?: string, jsonBody?: any) {
    super(message);
    this.httpStatus = status;
    this.jsonBody = jsonBody;
  }
}
