<script setup lang="ts">
import SearchField from '@/components/SearchField.vue';

const inputFieldModel = defineModel<string>({ default: '' });
</script>

<template>
  <q-card-section class="row bg-primary q-py-sm">
    <slot name="headline"></slot>
    <q-space />
    <search-field v-model="inputFieldModel" class="q-py-sm" />
  </q-card-section>
</template>

<style scoped lang="sass"></style>
