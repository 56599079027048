import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { Dialog, Quasar } from 'quasar';

// Import icon libraries
import '@quasar/extras/roboto-font/roboto-font.css';
import '@quasar/extras/material-icons/material-icons.css';

// Import Quasar css
import 'quasar/src/css/index.sass';

import './assets/dfs-colors.css';
import './assets/newsimweb.css';

import App from './App.vue';
import router from './router';
import { STORAGE_KEY, useLoginStore } from '@/stores/LoginStore';
import { errorDialog } from '@/components/dialogs/dialogs';
import { HttpError } from '@/connection/HttpError';

const app = createApp(App);

app.config.errorHandler = async (error, vm, info) => {
  if (!(error instanceof HttpError)) {
    console.error(`Error! error=${error}, info=${info}`);
    return;
  }
  await handleHttpError(error);
};

app.use(createPinia());
app.use(router);
app.use(Quasar, {
  plugins: { Dialog }, // import Quasar plugins and add here
});

app.mount('#app');

const loginStore = useLoginStore();

let showsError = false;

/**
 * Force reload of all tabs on logout.
 */
window.addEventListener('storage', function (event) {
  if (event.key === STORAGE_KEY && !event.newValue) {
    window.location.reload();
  }
});

window.addEventListener('error', async function (e) {
  const error = e.error;
  e.preventDefault();
  e.stopPropagation();
  await error;
});

window.addEventListener('unhandledrejection', async function (e) {
  const error = e.reason;
  e.preventDefault();
  e.stopPropagation();
  await handleErrors(error);
});

async function handleErrors(error: unknown) {
  if (error instanceof HttpError) {
    return await handleHttpError(error);
  }

  if (error instanceof TypeError) {
    return handleTypeError(error);
  }
  throw error;
}

async function handleHttpError(httpError: HttpError) {
  if (httpError.httpStatus === 401) {
    await toLogin();
    return;
  }

  if (showsError) {
    console.debug('Error dialog already open, not opening more');
    return;
  }

  switch (httpError.httpStatus) {
    case 403:
      showsError = true;
      errorDialog(
        `Forbidden! You do not have the permission to access the requested resource.`,
      ).onOk(() => {
        showsError = false;
        router.replace({ name: 'overview' });
      });
      break;
    case 500:
      showsError = true;
      errorDialog(`Internal server error, please submit a bug report!`).onOk(() => {
        showsError = false;
      });
      break;
    case 502:
    case 503:
    case 504:
      showsError = true;
      errorDialog('Lost connection to server! Try to log back in.').onOk(() => {
        showsError = false;
        toLogin();
      });
      break;
    default:
      showsError = true;
      errorDialog(`Error, please submit a bug report! ${httpError.message}`).onOk(() => {
        showsError = false;
      });
      break;
  }
}

async function handleTypeError(error: TypeError) {
  if (showsError) {
    console.debug('Error dialog already open, not opening more');
    return;
  }
  console.log('Error processing server response!', error);
  showsError = true;
  errorDialog('Failed to process data received from server!').onOk(() => {
    showsError = false;
  });
}

function toLogin() {
  loginStore.clearSession();
  window.location.href = '/login';
}
