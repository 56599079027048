import { defineStore } from 'pinia';
import { RestApiRequest } from '@/restApiRequest';
import type { Authentication } from '@/model/Authentication.ts';

/**
 * Pinia store to track login state and user metadata.
 *
 * Uses window.localStorage to persist the state.
 * The state is only restored from local storage when the store is instantiated.
 *
 * localStorage is persistent even if the browser is restarted. With the backend currently only allowing session
 * cookies, this would cause the application to break on browser restart: localStorage says the users is logged in but
 * the backend does not agree.
 * To solve this an additional session cookie is set, the state will only be restored if the session cookie exists.
 *
 * window.sessionStorage can not be used. sessionStorage is scoped on a browser tab or window and the state would only
 * be copied if the 'duplicate tab' action would be used, not for new tabs or new windows.
 *
 * To log the user out of all tabs a window.eventListener on the localStorage has been registered in main.ts.
 * The listener reloads all Newsim-Web-Portal tabs if the stores key is removed from localStorage.
 */

export const STORAGE_KEY = 'userStore';

const defaults = {
  userName: 'N/A',
  loggedIn: false,
  role: '',
  csrfToken: '',
  userDisplayName: 'N/A',
};

export const useLoginStore = defineStore('auth', {
  state: () => {
    return restoreFromStorage();
  },
  getters: {
    isLoggedIn: (state) => state.loggedIn,
    isAdmin: (state) => 'ADMIN' === state.role,
    isCoach: (state) => 'COACH' === state.role,
  },
  actions: {
    login(auth: Authentication) {
      this.loggedIn = true;
      this.userName = auth.userName;
      this.csrfToken = auth.token;
      this.role = auth.role;
      this.userDisplayName = auth.userDisplayName;
      setSessionCookie();
      localStorage.setItem(STORAGE_KEY, JSON.stringify(this.$state));
    },

    clearSession() {
      removeSessionCookie();
      localStorage.removeItem(STORAGE_KEY);
      this.$state = { ...defaults };
    },

    async logout() {
      removeSessionCookie();
      localStorage.removeItem(STORAGE_KEY);
      this.$state = { ...defaults };
      await RestApiRequest.logout();
    },
  },
});

function setSessionCookie() {
  document.cookie = `newsimWebSession=true;Path=${import.meta.env.BASE_URL};SameSite=Strict`;
}

function removeSessionCookie() {
  document.cookie = `newsimWebSession=;max-age=0;Path=${import.meta.env.BASE_URL};SameSite=Strict`;
}

function hasSessionCookie() {
  return (
    document.cookie
      .split(';')
      .find((token) => token.startsWith('newsimWebSession='))
      ?.split('=')[1] === 'true'
  );
}

/**
 * Restore the state. Falls back to defaults if the browser has been restarted, if the stored JSON fails to parse or if
 * no data has been stored.
 */
function restoreFromStorage() {
  if (!hasSessionCookie()) {
    return { ...defaults };
  }
  const fromStore = localStorage.getItem(STORAGE_KEY);
  if (!fromStore) {
    return { ...defaults };
  }
  try {
    return JSON.parse(fromStore);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (e) {
    return { ...defaults };
  }
}
