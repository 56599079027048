import type { SessionInfo } from '@/model/SessionInfo';
import { getSimulationMode, SimulationMode } from '@/enums/SimulationMode';
import { type SimulatorSessionState } from '@/model/SimulatorSessionState';

interface ControlData {
  sessionStatus: SimulatorSessionState;
  simulationMode: SimulationMode;
  isHost: boolean;
  isSpectator: boolean;
}

const getControlData = (
  session: SessionInfo | undefined,
  host: boolean,
  spectator: boolean,
): ControlData => {
  return {
    sessionStatus: session?.sessionState || 'UNKNOWN',
    simulationMode: getSimulationMode(session),
    isHost: host,
    isSpectator: spectator,
  };
};

export type { ControlData };
export { getControlData };
