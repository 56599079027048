<script setup lang="ts">
import { type Ref, ref, watch } from 'vue';
import ActiveExerciseList from '@/components/overview/ActiveExerciseList.vue';
import AvailableExerciseList, {
  type ExpandCourseModel,
} from '@/components/overview/AvailableExerciseList.vue';
import ListHeader from '@/components/overview/ListHeader.vue';
import { setActiveListFilters, setAvailableListFilters } from '@/components/overview/ListFilters';
import {
  BaseAvailableExerciseProvider,
  type ExerciseDataCallback,
} from '@/components/overview/BaseAvailableExerciseProvider';
import type { ExerciseInfo } from '@/model/ExerciseInfo';
import { AvailableExerciseProvider } from '@/components/overview/AvailableExerciseProvider';
import { BaseExerciseSessionProvider } from '@/components/overview/BaseExerciseSessionProvider';
import { ExerciseSessionProvider } from '@/components/overview/ExerciseSessionProvider';

const TIMER_EXERCISES = 2 * 60 * 1000;
const TIMER_EXERCISE_SESSIONS = 2 * 1000;

const availableExFilterText = ref('');
const selectedSimModes: Ref<string[]> = ref([]);
const vrrFilterOn = ref(false);
const activeExFilterText = ref('');
const onlyRunningExercises = ref(false);

const availableSimModes: Ref<string[]> = ref([]);
const exercisesWithVrr: Ref<boolean> = ref(false);

const expandAllCourses = ref(true);

const expandCourseModel: Ref<ExpandCourseModel> = ref({}) as Ref<ExpandCourseModel>;
let backupExpandCourseModel: ExpandCourseModel = {};

const exercisesUpdated: ExerciseDataCallback = (exerciseMessages: ExerciseInfo[]) => {
  const simModes: string[] = [];
  let vrrExists: boolean = false;
  exerciseMessages.forEach((exercise) => {
    if (!simModes.includes(exercise.simulationMode)) {
      simModes.push(exercise.simulationMode);
    }
    if (exercise.hasVrr) {
      vrrExists = true;
    }
  });
  availableSimModes.value = simModes;
  exercisesWithVrr.value = vrrExists;
};

function createAvailableExerciseProvider(): BaseAvailableExerciseProvider {
  const availableExercise = new AvailableExerciseProvider('AvailableExercises', TIMER_EXERCISES);
  // subscribe to update
  availableExercise.subscribeForExerciseMessages(exercisesUpdated);
  return availableExercise;
}

function createExerciseSessionProvider(): BaseExerciseSessionProvider {
  // subscribe to update
  return new ExerciseSessionProvider('ExerciseSessions', TIMER_EXERCISE_SESSIONS);
}

const height = ref('200px');

function onResize(size: { height: number; width: number }) {
  height.value = size.height ? `calc(100vh - 275px)` : '100vh';
  if (window.innerWidth < 1440) {
    height.value = `calc(50vh - 215px)`;
  }
}

function onToggleExpandAllCourses() {
  Object.keys(expandCourseModel.value).forEach(
    (key) => (expandCourseModel.value[key] = expandAllCourses.value),
  );
}

function onToggleSingleCourse() {
  expandAllCourses.value = Object.values(expandCourseModel.value).every((value) => value);
}

watch(availableExFilterText, filterInAllCourses);

function filterInAllCourses(filterText: string) {
  if (filterText.length > 0) {
    if (Object.keys(backupExpandCourseModel).length === 0) {
      backupExpandCourseModel = JSON.parse(JSON.stringify(expandCourseModel.value));
      expandAllCourses.value = true;
      onToggleExpandAllCourses();
    }
  } else {
    if (Object.keys(backupExpandCourseModel).length > 0) {
      expandCourseModel.value = JSON.parse(JSON.stringify(backupExpandCourseModel));
      backupExpandCourseModel = {};
      onToggleSingleCourse();
    }
  }
}
</script>

<template>
  <q-page>
    <q-resize-observer @resize="onResize"></q-resize-observer>
    <div class="q-pa-lg row q-gutter-md justify-evenly">
      <q-card class="col-grow" data-testid="available-exercise-frame">
        <ListHeader v-model="availableExFilterText">
          <template #headline>
            <div class="q-pa-md text-h5">Available Exercises</div>
          </template>
        </ListHeader>

        <q-separator dark />

        <q-card-actions class="q-pa-md q-gutter-md">
          <q-toggle
            v-model="expandAllCourses"
            label="Expand all"
            dense
            color="primary"
            keep-color
            size="xs"
            @click="onToggleExpandAllCourses"
          />
          <q-space />
          <template v-if="availableSimModes.length > 1">
            <q-toggle
              v-for="mode in availableSimModes"
              :key="mode"
              v-model="selectedSimModes"
              :label="mode"
              :val="mode"
              size="xs"
              color="primary"
              keep-color
              dense
            />
          </template>
          <q-space />
          <template v-if="exercisesWithVrr">
            <q-toggle
              v-model="vrrFilterOn"
              icon="record_voice_over"
              label="with VRR"
              color="primary"
              keep-color
              size="sm"
              dense
            />
          </template>
        </q-card-actions>

        <q-separator />
        <q-card-section class="q-pa-xs">
          <div class="q-pa-xs">
            <AvailableExerciseList
              v-model="expandCourseModel"
              :filters="
                setAvailableListFilters(selectedSimModes, availableExFilterText, vrrFilterOn)
              "
              :exercises="createAvailableExerciseProvider()"
              style="min-height: 120px"
              :style="{ height: height }"
              @toggle="onToggleSingleCourse"
            />
          </div>
        </q-card-section>
      </q-card>

      <q-card class="col-grow" data-testid="active-exercise-frame">
        <ListHeader v-model="activeExFilterText">
          <template #headline>
            <div class="q-pa-md text-h5">Active Exercises</div>
          </template>
        </ListHeader>
        <!--        <q-separator />-->
        <!--        <q-card-actions class="q-pa-md q-gutter-md">-->
        <!--          <q-space />-->
        <!--          <q-toggle-->
        <!--            v-model="onlyRunningExercises"-->
        <!--            label="'running' Exercises"-->
        <!--            size="xs"-->
        <!--            color="primary"-->
        <!--            keep-color-->
        <!--            dense-->
        <!--          />-->
        <!--        </q-card-actions>-->
        <!--<q-separator />-->
        <q-card-section class="q-pa-xs">
          <div class="q-pa-xs">
            <ActiveExerciseList
              :filters="setActiveListFilters(onlyRunningExercises, activeExFilterText)"
              :exercise-sessions="createExerciseSessionProvider()"
              style="min-height: 120px"
              :style="{ height: height }"
            />
          </div>
        </q-card-section>
      </q-card>
    </div>
  </q-page>
</template>

<style scoped lang="sass">
.q-card
  min-width: 440px

:deep(.exercise-text)
  font-size: 1.4em

:deep(.btn-small)
  width: 90px
</style>
