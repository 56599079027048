export enum PositionType {
  'adjacent' = 'language',
  'executive' = 'radar',
  'none' = 'question_mark',
  'pilot' = 'flight',
  'planner' = 'list',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  'superpilot' = 'language',
}

export function isAdjacentOrSuperpilot(type: string): boolean {
  return ['adjacent', 'superpilot'].includes(type);
}

export enum AudioState {
  'mute' = 'mic_off',
  'off' = 'mic_none',
  'on' = 'mic',
}

export type ParticipantStatus = 'CONNECTED' | 'READY' | 'DISCONNECTED' | 'UNKNOWN';

export function isUserInSession(status: ParticipantStatus) {
  return 'CONNECTED' === status || 'READY' === status;
}

export class Participant {
  private readonly _userName: string;
  private readonly _userDisplayName: string;
  private readonly _status: ParticipantStatus;
  private readonly _positionType: PositionType;
  private readonly _sectorName: string;
  private readonly _avatar: URL;
  private readonly _audioState: AudioState;
  private _speaking: boolean = false;

  public static readonly DEFAULT_AVATAR = new URL('/avatar.svg', window.location.origin);

  constructor(
    name: string,
    avatar: URL = Participant.DEFAULT_AVATAR,
    sectorName: string = 'N/A',
    positionType: PositionType = PositionType.none,
    audioState: AudioState = AudioState.off,
    userDisplayName: string,
    status: ParticipantStatus = 'CONNECTED',
  ) {
    this._userName = name;
    this._avatar = avatar;
    this._sectorName = sectorName;
    this._positionType = positionType;
    this._audioState = audioState;
    this._status = status;
    this._userDisplayName = userDisplayName;
  }

  get userName() {
    return this._userName;
  }

  get userDisplayName() {
    return this._userDisplayName;
  }

  get status() {
    return this._status;
  }

  get sectorName() {
    return this._sectorName;
  }

  get positionType() {
    return this._positionType;
  }

  get avatar() {
    return this._avatar.toString();
  }

  get audioState() {
    return this._audioState;
  }

  get speaking() {
    return this._speaking;
  }

  set speaking(speaking: boolean) {
    this._speaking = speaking;
  }
}

export class Participants {
  private readonly _currentUser: string;
  private _host: Participant | undefined;
  private readonly _participants: Participant[] = [];

  constructor(currentUser: string) {
    this._currentUser = currentUser;
  }

  public get host() {
    return this._host;
  }

  public replaceAll(sessionOwner: string, participants: Participant[]): Participants {
    this._participants.length = 0;
    for (const participant of participants) {
      if (participant.userName === sessionOwner) {
        this._host = participant;
        continue;
      }
      this._participants.push(participant);
    }
    return this;
  }

  public get participants() {
    const currentUser = this._participants.find((p) => p.userName === this._currentUser);
    const filtered = this._participants.filter((p) => p.userName !== this._currentUser);
    // move current user to start of list
    if (currentUser) {
      filtered.unshift(currentUser);
    }
    return filtered;
  }
}
