<script setup lang="ts">
import { computed, ref, toRefs } from 'vue';
import ButtonComponent from '@/components/backstage/ButtonComponent.vue';
import type { ControlData } from '@/components/backstage/ControlArea';
import { SimulationMode } from '@/enums/SimulationMode';
import { isRunning } from '@/model/SimulatorSessionState';

interface Props {
  controlData: ControlData;
}

const props = defineProps<Props>();
const { controlData } = toRefs(props);

const emit = defineEmits(['pause', 'run', 'leave', 'terminate', 'openDisplay', 'bugReport']);

enum ExecutionSpeed {
  RUN = 'Run',
  PAUSE = 'Pause',
}

const executionSpeedLabel = ref(ExecutionSpeed.PAUSE);
const executionSpeedTooltip = computed(() => executionSpeedLabel.value + ' the Exercise');
const isSimsys = computed(() => controlData.value.simulationMode === SimulationMode.SIMSYS);

const onOpenDisplay = () => {
  if (isRunning(controlData.value.sessionStatus)) {
    emit('openDisplay');
  }
};

const onExecutionSpeed = () => {
  if (!isSimsys.value) {
    if (executionSpeedLabel.value === ExecutionSpeed.PAUSE) {
      emit('pause');
      executionSpeedLabel.value = ExecutionSpeed.RUN;
    } else if (executionSpeedLabel.value === ExecutionSpeed.RUN) {
      emit('run');
      executionSpeedLabel.value = ExecutionSpeed.PAUSE;
    }
  }
};
</script>

<template>
  <div class="row q-mt-md q-gutter-md">
    <ButtonComponent
      v-if="!controlData.isHost"
      :label="controlData.isSpectator ? 'Back' : 'Leave'"
      color="secondary"
      tooltip="Leave the Session"
      data-testid="back-or-leave"
      @click="$emit('leave')"
    />
    <ButtonComponent
      v-if="controlData.isHost"
      label="Terminate"
      color="secondary"
      tooltip="Terminate the Session"
      data-testid="terminate"
      @click="$emit('terminate')"
    />
    <ButtonComponent
      v-if="!controlData.isSpectator"
      label="Open Display"
      color="secondary"
      tooltip="Open the Simulation Display"
      data-testid="open-display"
      :disable="!isRunning(controlData.sessionStatus)"
      @click="onOpenDisplay"
    />
    <ButtonComponent
      v-if="!isSimsys && false"
      :label="executionSpeedLabel"
      color="secondary"
      :tooltip="executionSpeedTooltip"
      data-testid="run-or-pause"
      @click="onExecutionSpeed"
    />
  </div>
</template>

<style scoped lang="sass"></style>
