<script setup lang="ts">
import { computed, onMounted, type Ref, ref, toRefs, watch } from 'vue';
import type { QBadge, QBtn } from 'quasar';
import type { WorkspaceAssignment } from '@/model/WorkspaceAssignment';
import { VrrState } from '@/enums/VrrState';
import { getIconByPositionType, isVrrPossible } from '@/components/lobby/WorkspaceUtil';
import { enableVrrDialog } from '@/components/dialogs/dialogs';
import { isAdjacentOrSuperpilot, PositionType } from '@/components/participants/Participants';

const props = defineProps<WorkspaceAssignment>();
const {
  id,
  positionType,
  position,
  vrrState,
  assignable,
  assignee,
  selected,
  vrrSelected,
  atsetupPosition,
  userDisplayName,
  sectors,
  disable,
} = toRefs(props);

const emits = defineEmits(['selectWorkspace']);

const myBadge = ref(null) as Ref<QBadge | null>;
const myButton = ref(null) as Ref<QBtn | null>;
const iconName = ref('');
const adjacentTooltipVisible = computed(() => {
  const posType: string = positionType.value.toLowerCase() as keyof typeof PositionType;
  return isAdjacentOrSuperpilot(posType);
});

const pilotTooltipVisible = computed(() => {
  const posType: string = positionType.value.toLowerCase() as keyof typeof PositionType;
  return posType === 'pilot';
});

const vrrPossible = computed(() => {
  return isVrrPossible(vrrState.value);
});

const clickCard = () => {
  if (assignable.value) {
    emits('selectWorkspace', id.value, !selected.value, vrrSelected.value);
  }
};

const clickVrr = () => {
  if (assignable.value) {
    if (vrrPossible.value) {
      if (!selected.value) {
        clickCard();
      } else {
        enableVrrDialog()
          .onOk(() => {
            selectVrr(true);
          })
          .onCancel(() => {
            selectVrr(false);
          });
      }
    } else {
      clickCard();
    }
  }
};

const selectVrr = (vrrSelected: boolean) => {
  emits('selectWorkspace', id.value, selected.value, vrrSelected);
};

watch([selected], (): void => {
  if (assignable.value) {
    if (selected.value && vrrPossible.value) {
      enableVrrDialog()
        .onOk(() => {
          selectVrr(true);
        })
        .onCancel(() => {
          selectVrr(false);
        });
    }
  }
});

watch([assignee, assignable], (): void => {
  updateBadgeCursor();
});

watch([vrrState], (): void => {
  updateVrrBadge(vrrState.value);
});

onMounted(() => {
  initIcon();
  initBadge();
});

const initIcon = () => {
  iconName.value = getIconByPositionType(positionType.value);
};

const initBadge = () => {
  // Hint:
  // -----
  // Quasar's q-badge component has no click event implemented.
  // So, to make mouse clicks possible a q-btn component can be used with a q-badge as child element.
  // To make it realistic we are overlapping both components.

  const buttonElement = getElementQBtn();
  const badgeElement = getElementQBadge();

  if (buttonElement !== undefined && badgeElement !== undefined) {
    // overlap q-btn and q-badge
    const rect = badgeElement.getBoundingClientRect();
    buttonElement.style.minWidth = rect.width - 2 + 'px';
    buttonElement.style.minHeight = rect.height - 4 + 'px';

    badgeElement.style.display = 'none';
    buttonElement.hidden = true;
    buttonElement.style.cursor = 'default';

    if (vrrPossible.value) {
      badgeElement.style.display = 'block';

      if (assignable.value) {
        buttonElement.style.cursor = 'pointer';
      }
    }
  }
};

const updateBadgeCursor = () => {
  const buttonElement = getElementQBtn();
  if (buttonElement !== undefined) {
    buttonElement.style.cursor = assignable.value && vrrPossible.value ? 'pointer' : 'default';
  }
};

const updateVrrBadge = (vrrStateValue: VrrState) => {
  const buttonElement = getElementQBtn();
  const badgeElement = getElementQBadge();

  if (buttonElement !== undefined && badgeElement !== undefined) {
    if (vrrStateValue === VrrState.DISABLED) {
      badgeElement.style.display = 'none';
    }
    if (vrrStateValue === VrrState.VACANT) {
      badgeElement.style.display = 'block';
      if (assignable.value) {
        buttonElement.style.cursor = 'pointer';
      }
    }
  }
};

const getElementQBtn = (): HTMLButtonElement | undefined => {
  if (myButton.value) {
    const qButton = myButton.value;
    return qButton.$el;
  }
  return undefined;
};

const getElementQBadge = (): HTMLButtonElement | undefined => {
  if (myBadge.value) {
    const qBadge = myBadge.value;
    return qBadge.$el;
  }
  return undefined;
};
</script>

<template>
  <q-card
    bordered
    class="position-card"
    :class="{
      'is-selected': assignable && selected,
      'not-selected': assignable && !selected,
      'not-assignable': !assignable,
      disabled: disable,
    }"
    @click="clickCard"
  >
    <q-card-actions class="q-pa-none" align="right">
      <q-btn ref="myButton" class="btn--no-hover" :ripple="false" flat @click.stop="clickVrr">
        <q-badge
          ref="myBadge"
          floating
          :class="vrrState === VrrState.OCCUPIED ? 'vrr-selected' : 'vrr-not-selected'"
        >
          <q-icon name="record_voice_over" color="white" size="20px" />
        </q-badge>
      </q-btn>
    </q-card-actions>
    <q-card-section class="text-center q-pa-xs">
      <q-icon :name="iconName" size="2em" />
    </q-card-section>
    <q-card-section class="text-center q-pa-none">
      {{ position }}
      <template v-if="adjacentTooltipVisible">
        <q-tooltip max-width="150px"> {{ sectors.join(', ') }}</q-tooltip>
      </template>
      <template v-if="pilotTooltipVisible">
        <q-tooltip max-width="150px"> {{ atsetupPosition }}</q-tooltip>
      </template>
    </q-card-section>
    <q-card-section class="text-center q-pa-xs small-font ellipsis">
      {{ userDisplayName }}
    </q-card-section>
  </q-card>
</template>

<style scoped lang="sass">

.position-card
  width: 120px
  height: 110px
  cursor: pointer

  &.disabled
    cursor: not-allowed
    opacity: .7 !important

.not-assignable
  background-color: var(--grey-200)
  cursor: not-allowed

.is-selected
  background-color: #cce5ff

.not-selected
  background-color: white

.vrr-selected
  background-color: $accent

.vrr-not-selected
  background-color: var(--grey-300)

.small-font
  font-size: 11px

// workaround to disable Quasar's button hover effect
:deep(.q-btn.btn--no-hover .q-focus-helper)
  display: none
</style>
