<script setup lang="ts">
const inputFieldModel = defineModel<string>({ default: '' });
</script>

<template>
  <q-input v-model="inputFieldModel" outlined bottom-slots label="Search" bg-color="white" dense>
    <template #append>
      <q-icon
        v-if="inputFieldModel !== ''"
        name="close"
        class="cursor-pointer"
        @click="inputFieldModel = ''"
      />
      <q-icon name="search" />
    </template>
  </q-input>
</template>

<style lang="sass" scoped></style>
