<script setup lang="ts">
import SearchField from '@/components/SearchField.vue';

const positionFilter = defineModel<string>('positionFilter', { required: true });
const vrrOnly = defineModel<boolean>('vrrOnly', { required: true });
</script>

<template>
  <div class="row q-gutter-md items-baseline">
    <search-field v-model="positionFilter" class="btn-fixed-width-l" />
    <q-toggle v-model="vrrOnly" icon="record_voice_over" label="VRR" keep-color size="sm" dense />
  </div>
</template>

<style lang="sass" scoped>
.btn-fixed-width-l
  width: 250px
</style>
