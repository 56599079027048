<script setup lang="ts"></script>

<template>
  <q-item-section top style="width: 20px">
    <slot name="first-line" />
    <slot name="second-line" />
  </q-item-section>
  <q-item-section side>
    <div class="row q-gutter-xs self-center">
      <div class="col self-center">
        <slot name="additional" />
      </div>
      <div class="self-center">
        <slot name="info-button" />
      </div>
      <slot name="action-button" />
    </div>
  </q-item-section>
</template>

<style scoped lang="sass"></style>
