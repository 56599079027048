import { type Ref, ref } from 'vue';
import {
  AudioState,
  Participant,
  Participants,
  PositionType,
} from '@/components/participants/Participants';
import { ScheduledCall } from '@/connection/ScheduledCall';
import type { SessionInfo } from '@/model/SessionInfo';
import { RestApiRequest } from '@/restApiRequest';
import { HttpError } from '@/connection/HttpError';

/**
 * Base class to handle the periodic data fetching and parsing
 *
 * @param sessionId : string session id to fetch data for
 * @param csrfToken : string the current CSRF token
 * @param userName : string the current username
 *
 * @returns sessionInfo : Ref<SessionInfo | undefined> the last session info retrieved
 * @returns participants : Ref<Participants> the sessions participants
 * @returns isHost : Ref<boolean> true if the current user is the host of the session
 * @returns sessionNotFound : Ref<boolean> true if the backend responded with HTTP status 404 for the sessionId
 * @returns sessionInfoScheduler : ScheduledCall<void> reference to the scheduler polling the backend, use to stop the scheduler on page unload
 */
export class FetchSessionInfo {
  private readonly _sessionId: string = '';
  private readonly _csrfToken: string = '';
  protected readonly _userName: string = '';
  private readonly _sessionInfo = ref<SessionInfo>();

  protected readonly _participants: Ref<Participants>;
  protected readonly _isHost = ref(false);
  protected readonly _sessionError = ref(undefined) as Ref<HttpError | undefined>;
  protected readonly _sessionInfoScheduler: ScheduledCall<void>;
  protected readonly _sessionErrorMsg = ref('');

  private readonly _userIsInSession = ref(false);

  constructor(sessionId: string, csrfToken: string, userName: string) {
    this._sessionId = sessionId;
    this._csrfToken = csrfToken;
    this._userName = userName;

    this._participants = ref(new Participants(this._userName)) as Ref<Participants>;
    this._sessionInfoScheduler = new ScheduledCall<void>(
      'SessionInfo',
      500,
      this.getSessionInfo.bind(this),
    );
  }

  async getSessionInfo() {
    try {
      const sessionInfo = await RestApiRequest.getSessionInfo(this._csrfToken, this._sessionId);

      this.sessionInfo.value = sessionInfo;
      this._isHost.value = sessionInfo.ownerId === this._userName;
      this.generateParticipantList(sessionInfo);
      this._userIsInSession.value = this.isCurrentUserInSession();
      this._sessionErrorMsg.value = sessionInfo.errorMsg;
      return sessionInfo;
    } catch (err) {
      console.debug('Failed to get session info. sessionId=' + this._sessionId + '. Error=' + err);
      this._sessionInfoScheduler.stop();
      if (err instanceof HttpError) {
        this._sessionError.value = err;
        return;
      }
      throw err;
    }
  }

  private isCurrentUserInSession() {
    if (this._isHost.value) {
      return true;
    }
    const found = this._participants.value.participants.find((p) => p.userName === this._userName);
    if (found) {
      return true;
    }
    return false;
  }

  generateParticipantList(sessionInfo: SessionInfo) {
    const participantArray: Participant[] = [];
    for (const user in sessionInfo.participantInfo) {
      const participantInfo = sessionInfo.participantInfo[user];
      participantArray.push(
        new Participant(
          user,
          Participant.DEFAULT_AVATAR,
          participantInfo.positionName,
          PositionType[participantInfo.positionType as keyof typeof PositionType],
          AudioState.off,
          participantInfo.userDisplayName,
          participantInfo.status,
        ),
      );
    }
    this._participants.value.replaceAll(sessionInfo.ownerId, participantArray);
  }

  get sessionInfo() {
    return this._sessionInfo;
  }

  get participants() {
    return this._participants;
  }

  get isHost() {
    return this._isHost;
  }

  get sessionError() {
    return this._sessionError;
  }

  get sessionInfoScheduler() {
    return this._sessionInfoScheduler;
  }

  get userIsInSession() {
    return this._userIsInSession;
  }

  get sessionErrorMsg() {
    return this._sessionErrorMsg;
  }
}
