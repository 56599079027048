<script setup lang="ts">
import { onBeforeMount, onUnmounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import InfoArea from '@/components/backstage/InfoArea.vue';
import ControlArea from '@/components/backstage/ControlArea.vue';
import ParticipantList from '@/components/participants/ParticipantList.vue';
import { getInfoData, type InfoData } from '@/components/backstage/InfoArea';
import { type ControlData, getControlData } from '@/components/backstage/ControlArea';
import { FetchSessionInfoBackstage } from '@/components/helper/FetchSessionInfoBackstage';
import { useLoginStore } from '@/stores/LoginStore';
import { useLeaveLobby } from '@/components/lobby/LeaveLobby';
import { HttpError } from '@/connection/HttpError';
import { sessionLeaveDialog, sessionLostDialog } from '@/components/dialogs/dialogs';
import { ScheduledCall } from '@/connection/ScheduledCall.ts';
import { RestApiRequest } from '@/restApiRequest.ts';
import type { SimulationTimes } from '@/model/SimulationTimes.ts';
import type { SimulatorSessionState } from '@/model/SimulatorSessionState.ts';

const OVERVIEW_PAGE = {
  name: 'overview',
};

const loginStore = useLoginStore();
const route = useRoute();
const router = useRouter();

const sessionId = Array.isArray(route.params.sessionId)
  ? route.params.sessionId[0]
  : route.params.sessionId;

const fetchSessionInfo = new FetchSessionInfoBackstage(
  sessionId,
  loginStore.csrfToken,
  loginStore.userName,
);

const {
  sessionInfo,
  participants,
  isHost,
  userIsInSession,
  sessionError,
  sessionInfoScheduler,
  sessionErrorMsg,
} = fetchSessionInfo.get();

const { leaveLobbyAction, terminationConfirmed } = useLeaveLobby(
  sessionId,
  isHost,
  sessionError,
  sessionErrorMsg,
);

const infoData = ref<InfoData>(getInfoData(undefined));
const controlData = ref<ControlData>(
  getControlData(undefined, isHost.value, !userIsInSession.value),
);
const simulationTimes = ref<SimulationTimes>();
const sessionState = ref<SimulatorSessionState>();
const sessionLostDialogOpen = ref(false);

watch(
  sessionInfo,
  () => {
    infoData.value = getInfoData(sessionInfo.value);
    controlData.value = getControlData(sessionInfo.value, isHost.value, !userIsInSession.value);
    sessionState.value = sessionInfo.value?.sessionState;
  },
  { immediate: true },
);
watch(sessionError, showSessionLostDialog, { immediate: true });
watch(sessionState, watchSessionState, { immediate: true });

const SIMTIMES_UPDATE_INTERVAL_MILLIS = 450;

const simTimesScheduler = new ScheduledCall<void>(
  'simulationTimes',
  SIMTIMES_UPDATE_INTERVAL_MILLIS,
  getSimulationTimes,
);
const simStatusSchedulerStarted: boolean = false;

async function getSimulationTimes() {
  RestApiRequest.getSimulationTimes(sessionId, loginStore.csrfToken)
    .then((simTimes) => (simulationTimes.value = simTimes))
    .catch(() => stopScheduler());
}

function stopScheduler() {
  simTimesScheduler.stop();
}

onBeforeMount(() => {
  sessionInfoScheduler.start();
});

onUnmounted(() => {
  sessionInfoScheduler.stop();
  simTimesScheduler.stop();
});

const onBugReport = (text: string) => {
  if (text.length) {
    console.log(
      '[ToDo] onReportBug() -> create jira ticket with session-id: "' +
        sessionId +
        '" and bug description: "' +
        text +
        '"',
    );
  }
};

const onOpenDisplay = () => {
  const workingPositionUrl = router.resolve({
    name: 'working_position',
    params: { sessionId: sessionId },
  });
  window.open(workingPositionUrl.href, '_blank');
};

const onLeave = () => {
  if (!userIsInSession.value) {
    leaveLobbyAction();
  } else {
    sessionLeaveDialog().onOk(() => {
      leaveLobbyAction();
    });
  }
};

const onPause = () => {
  // Background:
  // CF0, CFn is a command which can be sent to simulator (atcoach).
  // CF0 is speed 0 (=Pause), CF1 ist speed 1 (=Run) .... CF20 is speed 20 (=FastForward).

  console.log('[ToDo] onPause() -> sending execution speed command CF0');
};

const onRun = () => {
  console.log('[ToDo] onRun() -> sending execution speed command CF1');
};

function showSessionLostDialog(error: HttpError | undefined) {
  if (!error || sessionLostDialogOpen.value) {
    return;
  }
  sessionInfoScheduler.stop();
  if (isHost.value && terminationConfirmed.value) {
    return;
  }
  if (error.httpStatus !== 400 && error.httpStatus !== 404) {
    throw error;
  }
  sessionLostDialogOpen.value = true;

  sessionLostDialog().onOk(() => {
    sessionLostDialogOpen.value = false;
    terminationConfirmed.value = true;
    router.push(OVERVIEW_PAGE);
  });
}

function watchSessionState(simState: SimulatorSessionState | undefined) {
  if (
    !simStatusSchedulerStarted &&
    simState != undefined &&
    simState != 'STARTING' &&
    simState != 'UNKNOWN'
  ) {
    simTimesScheduler.start();
  }
}
</script>

<template>
  <q-page>
    <div class="fit row justify-between q-pa-md">
      <div class="col-xs-auto col-lg-8 q-pa-sm" style="min-width: 440px">
        <InfoArea :info-data="infoData" :simulation-times="simulationTimes" />
        <ControlArea
          :control-data="controlData"
          @pause="onPause"
          @run="onRun"
          @leave="onLeave"
          @terminate="leaveLobbyAction"
          @open-display="onOpenDisplay"
          @bug-report="onBugReport"
        />
      </div>
      <div class="col-xs-12 col-lg-4 q-pa-sm" style="min-width: 20em; max-width: 20em">
        <participant-list :participants="participants" />
      </div>
    </div>
  </q-page>
</template>

<style scoped lang="sass">
.q-page
  display: grid
  grid-template-rows: auto 1fr
</style>
