<script setup lang="ts">
import ImageList from '@/components/dashboard/ImageList.vue';
import { RestApiRequest } from '@/restApiRequest';
import { onBeforeMount, onUnmounted, ref, type Ref } from 'vue';
import { useLoginStore } from '@/stores/LoginStore';
import SessionStatusList from '@/components/dashboard/SessionStatusList.vue';
import PodStatusList from '@/components/dashboard/PodStatusList.vue';
import { ScheduledCall } from '@/connection/ScheduledCall';
import type { DashboardInfo } from '@/model/DashboardInfo';
import type { DashboardImageInfo } from '@/model/DashboardImageInfo';
import type { DashboardPodInfo } from '@/model/DashboardPodInfo';
import type { DashboardSessionInfo } from '@/model/DashboardSessionInfo';

const loginStore = useLoginStore();
const imageInfos: Ref<DashboardImageInfo[]> = ref([]);
const sessionInfos: Ref<DashboardSessionInfo[]> = ref([]);
const podInfos: Ref<DashboardPodInfo[]> = ref([]);

const TIMEOUT_FOR_DASHBOARD_UPDATE = 1000; // 1 sec

const dashboardScheduler = new ScheduledCall<void>(
  'dashboardData',
  TIMEOUT_FOR_DASHBOARD_UPDATE,
  getDashboardData,
);

const parseDashboardInfo = (dashboardInfo: DashboardInfo) => {
  setImageInfo(dashboardInfo.imageNameAndStatusMap);
  setActiveSessionInfo(dashboardInfo.sessionIdAndStatusMap);
  setRunningPodInfo(dashboardInfo.podNameAndInfoMap);
};

const setImageInfo = (images: { [key: string]: string }) => {
  imageInfos.value.length = 0;
  for (const key in images) {
    const version: string = images[key];
    const newImage: DashboardImageInfo = { name: key, version: version };
    imageInfos.value.push(newImage);
  }
};

const setActiveSessionInfo = (sessions: { [key: string]: string }) => {
  sessionInfos.value.length = 0;
  for (const key in sessions) {
    const status: string = sessions[key];
    const newSessionInfo: DashboardSessionInfo = { name: key, status: status };
    sessionInfos.value.push(newSessionInfo);
  }
};

const setRunningPodInfo = (pods: { [key: string]: DashboardPodInfo }) => {
  podInfos.value.length = 0;
  for (const key in pods) {
    const podInfo: DashboardPodInfo = pods[key];
    const newPodInfo: DashboardPodInfo = {
      name: key,
      sessionId: podInfo.sessionId,
      upTime: podInfo.upTime,
      status: podInfo.status.toString(),
      podOwner: podInfo.podOwner,
      isOrphaned: podInfo.isOrphaned,
    };
    podInfos.value.push(newPodInfo);
  }
};

async function getDashboardData() {
  try {
    await RestApiRequest.getDashboard(loginStore.csrfToken, parseDashboardInfo);
  } catch (error) {
    dashboardScheduler.stop();
    throw error;
  }
}

onBeforeMount(() => {
  dashboardScheduler.start();
});

onUnmounted(() => {
  dashboardScheduler.stop();
});
</script>

<template>
  <q-page>
    <div class="row">
      <div class="q-pa-sm col-xs-12 col-md-6 q-gutter-md justify-evenly">
        <SessionStatusList :session-infos="sessionInfos" />
      </div>
      <div class="q-pa-sm col-xs-12 col-md-6 q-gutter-md justify-evenly">
        <ImageList :image-infos="imageInfos" />
      </div>
      <div class="q-pa-sm col-12 q-gutter-md justify-evenly">
        <PodStatusList :pod-infos="podInfos" />
      </div>
    </div>
  </q-page>
</template>

<style scoped lang="sass">
:deep(.table-layout)
  .q-table__top,
  thead tr:first-child th
    background-color: var(--q-primary)
    color: white

  .q-table__bottom,
  thead tr:first-child th
    background-color: white
    color: black
</style>
