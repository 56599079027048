<script setup lang="ts"></script>
<template>
  <q-page>
    <div class="row justify-center">
      <div>
        <h1>Impressum - Legal notice</h1>
        <p>
          <span class="text-bold">DFS Deutsche Flugsicherung GmbH</span><br />
          Headquarters<br />
          Am DFS-Campus 10<br />
          63225 Langen<br />
          Germany<br />
        </p>
        <p>
          <span class="text-bold">Telephone:</span>&nbsp;+49 (0)6103 707-0<br />
          <span class="text-bold">Fax:</span>&nbsp;+49 (0)6103/707-1396<br />
        </p>
        <p>
          <span class="text-bold">Internet:</span>&nbsp;<a href="https://www.dfs.de">www.dfs.de</a
          ><br />
          <span class="text-bold">E-mail:</span>&nbsp;<a href="mailto:info@dfs.de">info@dfs.de</a
          ><br />
          <a href="https://dfs.de/homepage/en/data-protection/data-protection-info-dfs-de/"
            >Information according to Article 13 of the General Data Protection Regulation (GDPR)</a
          >
        </p>
        <p>
          <span class="text-bold">Registration</span><br />
          Offenbach district court<br />
          Commercial register entry 34977<br />
        </p>
        <p>
          <span class="text-bold">VAT registration number</span><br />
          DE 114 110 232
        </p>
        <p>
          <span class="text-bold">Chairperson of the Supervisory Board</span><br />
          Antje Geese
        </p>
        <p>
          <span class="text-bold">Executive Board</span><br />
          Arndt Schoenemann (Chairman)<br />
          Dirk Mahns<br />
          Friedrich-Wilhelm Menge<br />
          Andrea Wächter<br />
        </p>
      </div>
    </div>
  </q-page>
</template>
