import { v4 as uuidv4 } from 'uuid';
import type { SessionInfo } from '@/model/SessionInfo';

type SessionDataCallback = (value: SessionInfo[]) => void;

class BaseExerciseSessionProvider {
  protected sessionInfos: SessionInfo[];
  protected sessionCallbacks: Map<string, SessionDataCallback>;
  protected id: string;

  constructor(id: string) {
    this.id = id;
    this.sessionInfos = [];
    this.sessionCallbacks = new Map<string, SessionDataCallback>();
  }

  subscribeForSessionMessages(callback: SessionDataCallback): string {
    console.debug(`${this.id} : subscribe for session messages`);
    const subscriptionId: string = uuidv4();
    this.sessionCallbacks.set(subscriptionId, callback);
    return subscriptionId;
  }

  unsubscribeFromSessionMessages(subscriptionId: string) {
    console.debug(`${this.id} : unsubscribe from session messages`);
    this.sessionCallbacks.delete(subscriptionId);
  }

  start(): void {} // empty implementation
  stop(): void {} // empty implementation
}

export { BaseExerciseSessionProvider, type SessionDataCallback };
