import type { SessionReadiness } from '@/model/SessionReadiness.ts';

export function readinessToStartButtonTooltipText(sessionReadiness: SessionReadiness) {
  switch (sessionReadiness) {
    case 'WAITING_FOR_READY':
      return 'Waiting for all to be ready';
    case 'STARTING':
      return 'Starting session...';
    case 'WAITING_FOR_BACKEND':
      return 'Preparing system...';
    default:
      return 'Start session';
  }
}

export function readinessToBannerText(sessionReadiness: SessionReadiness, isHost: boolean) {
  switch (sessionReadiness) {
    case 'WAITING_FOR_READY':
      return 'Waiting for everyone to select a position and to be ready';
    case 'STARTING':
      return 'Session is starting...';
    case 'WAITING_FOR_BACKEND':
      return 'Preparing the system, this may take a minute or two. Please wait...';
    case 'STARTED':
      return 'The session has started, please open the working position tab';
    default:
      return isHost ? 'Ready to start the session' : 'Waiting for the host to start the session';
  }
}
