import type { SimulatorSessionState } from '@/model/SimulatorSessionState';
import type { ExerciseSessionData } from '@/components/overview/ExerciseSessionData';

export function setButtonColor(data: ExerciseSessionData): string {
  switch (data.sessionState as SimulatorSessionState) {
    case 'STARTING':
    case 'RUNNING':
    case 'PAUSE':
      return 'btn-grey';
    default:
      return 'bg-secondary';
  }
}

export function setButtonText(data: ExerciseSessionData): string {
  switch (data.sessionState as SimulatorSessionState) {
    case 'STARTING':
    case 'RUNNING':
    case 'PAUSE':
      return 'Details';
    default:
      return 'Join';
  }
}

export function setTooltipText(status: SimulatorSessionState): string {
  switch (status) {
    case 'STARTING':
    case 'RUNNING':
    case 'PAUSE':
      return 'Change to Backstage page';
    default:
      return 'Join the exercise';
  }
}
