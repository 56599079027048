<script setup lang="ts">
interface Props {
  title: string;
  message?: string;
  okLabel?: string;
  cancelLabel?: string;
  iconType?: string;
  colorType?: string;
  focus?: string;
}

const {
  title,
  message = 'N/A',
  okLabel = 'Ok',
  cancelLabel = 'Cancel',
  iconType = 'public',
  colorType = 'primary',
  focus = 'ok',
} = defineProps<Props>();

const emit = defineEmits(['ok', 'cancel']);

const onOk = () => {
  emit('ok');
};

const onCancel = () => {
  emit('cancel');
};
</script>

<template>
  <q-dialog persistent no-shake>
    <q-card>
      <q-card-section>
        <q-avatar :icon="iconType" :color="colorType" text-color="white" />
        <span class="text-h6 text-bold q-ml-sm"> {{ title }} </span>
      </q-card-section>
      <q-separator />
      <q-card-section>
        <div>{{ message }}</div>
      </q-card-section>
      <q-separator />
      <q-card-actions align="right">
        <q-btn
          v-close-popup
          :flat="focus !== 'cancel'"
          :label="cancelLabel"
          :color="colorType"
          @click="onCancel"
        />
        <q-btn
          v-close-popup
          :flat="focus !== 'ok'"
          :label="okLabel"
          :color="colorType"
          @click="onOk"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<style scoped lang="sass">
.q-card
  width: 75%
</style>
