<script setup lang="ts">
import { computed, ref } from 'vue';
import { matErrorOutline } from '@quasar/extras/material-icons';
import { useLoginStore } from '@/stores/LoginStore';
import { RestApiRequest } from '@/restApiRequest';
import router from '@/router';
import type { Authentication } from '@/model/Authentication.ts';

const loginStore = useLoginStore();

const username = ref('');
const password = ref('');
const message = ref('');

const isValidUsername = computed(() => {
  return username.value !== '';
});

const isValidPassword = computed(() => {
  return password.value !== '';
});

const isValid = computed(() => {
  return isValidUsername.value && isValidPassword.value;
});

const onSubmit = () => {
  RestApiRequest.login({ name: username.value, password: password.value }, success, failure);
};

const success = (auth: Authentication) => {
  resetInputFields();
  loginStore.login(auth);
  router.push({ name: 'overview' });
};

const failure = (errormsg: string) => {
  message.value = 'Error: ' + errormsg;
  resetInputFields();
};

const resetInputFields = () => {
  username.value = '';
  password.value = '';
};
</script>

<template>
  <q-card class="my-card">
    <q-img src="/login.png">
      <div class="absolute-bottom text-h5 text-weight-light">NEWSIMweb Portal</div>
    </q-img>
    <q-form @submit.prevent="onSubmit">
      <q-card-section>
        <div v-show="message.length" class="label text-negative text-subtitle1 text-weight-light">
          <q-icon :name="matErrorOutline" />
          {{ message }}
        </div>
        <q-input v-model="username" autofocus data-testid="username" label="User"></q-input>
        <q-input
          v-model="password"
          data-testid="password"
          label="Password"
          type="password"
        ></q-input>
      </q-card-section>
      <q-card-actions>
        <q-btn :disabled="!isValid" flat type="submit" color="primary">Login</q-btn>
      </q-card-actions>
    </q-form>
  </q-card>
</template>

<style lang="sass" scoped>
.my-card
  width: 100%
  max-width: 320px
</style>
