<script setup lang="ts">
import LoginUser from '@/components/LoginUser.vue';
import { useLoginStore } from '@/stores/LoginStore';
import router from '@/router';
import { onBeforeMount } from 'vue';

const loginStore = useLoginStore();

async function redirect() {
  if (loginStore.isLoggedIn) {
    await router.push({ name: 'overview' });
  }
}

onBeforeMount(redirect);
</script>

<template>
  <q-layout>
    <q-page-container>
      <q-page class="flex flex-center">
        <LoginUser />
      </q-page>
    </q-page-container>
  </q-layout>
</template>
