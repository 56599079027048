import type { SessionInfo } from '@/model/SessionInfo';

enum SimulationMode {
  VA = 'VA',
  ICAS = 'ICAS',
  SIMSYS = 'SIMSYS',
  P1 = 'P1',
  P2 = 'P2',
  UNKNOWN = 'UNKNOWN',
}

const getSimulationMode = (session: SessionInfo | undefined): SimulationMode => {
  if (session) {
    const simulationMode: string = session.exercise.simulationMode.toUpperCase();

    return simulationMode === SimulationMode.VA
      ? SimulationMode.VA
      : simulationMode === SimulationMode.ICAS
        ? SimulationMode.ICAS
        : simulationMode === SimulationMode.SIMSYS
          ? SimulationMode.SIMSYS
          : simulationMode === SimulationMode.P1
            ? SimulationMode.P1
            : simulationMode === SimulationMode.P2
              ? SimulationMode.P2
              : SimulationMode.UNKNOWN;
  }
  return SimulationMode.UNKNOWN;
};

export { SimulationMode, getSimulationMode };
