<script setup lang="ts">
import { computed, ref } from 'vue';
import { filterWorkspaces } from '@/components/lobby/WorkspaceUtil';
import SectorRow from '@/components/lobby/SectorRow.vue';
import PositionFilterRow from '@/components/lobby/PositionFilterRow.vue';
import type { WorkspaceAssignment } from '@/model/WorkspaceAssignment';
import { NameCheck } from '@/NameCheck';
import { PositionType } from '@/components/participants/Participants';

interface Props {
  workspaces: WorkspaceAssignment[];
  disable?: boolean;
}

const props = defineProps<Props>();
const emits = defineEmits(['selectWorkspace']);

const filteredWorkspaces = computed(() => {
  return filterWorkspaces(props.workspaces, positionFilter.value, vrrOnly.value, []);
});

const controllerPlannerWorkspaces = computed(() => {
  return filterWorkspaces(filteredWorkspaces.value, NameCheck.regexController, undefined, [
    PositionType.planner,
    PositionType.executive,
  ]);
});

const adjacentWorkspaces = computed(() => {
  return filterWorkspaces(filteredWorkspaces.value, NameCheck.regexAllAdjacent, undefined, [
    PositionType.adjacent,
    PositionType.superpilot,
  ]);
});

const pilotWorkspaces = computed(() => {
  return filterWorkspaces(filteredWorkspaces.value, NameCheck.regexPilot, undefined, [
    PositionType.pilot,
  ]);
});

const selectWorkspace = (id: number, selected: boolean, vrrSelected: boolean) => {
  emits('selectWorkspace', id, selected, vrrSelected);
};

const controllerTitle = ref('Controllers');
const pilotTitle = ref('Pilots');
const adjacentTitle = ref('Adjacents');
const positionFilter = ref('');
const vrrOnly = ref(false);
</script>

<template>
  <position-filter-row v-model:position-filter="positionFilter" v-model:vrr-only="vrrOnly" />
  <SectorRow
    v-if="controllerPlannerWorkspaces.length > 0"
    :workspaces="controllerPlannerWorkspaces"
    :title="controllerTitle"
    :disable="disable"
    @select-workspace="selectWorkspace"
  />
  <SectorRow
    v-if="pilotWorkspaces.length > 0"
    :workspaces="pilotWorkspaces"
    :title="pilotTitle"
    :disable="disable"
    @select-workspace="selectWorkspace"
  />
  <SectorRow
    v-if="adjacentWorkspaces.length > 0"
    :workspaces="adjacentWorkspaces"
    :title="adjacentTitle"
    :disable="disable"
    @select-workspace="selectWorkspace"
  />
</template>
