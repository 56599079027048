class NameCheck {
  static regexExecutive = '.*-E.*';
  static regexPlanner = '.*-P.*';
  static regexAdjacent = '^A[1-4]$';
  static regexPilot = '^P([1-9]|[1-5][0-9]|6[0-4])$';
  static regexSuperPilot = '^S([1-9]|1[0-6])$';
  static regexController = '(' + NameCheck.regexExecutive + '|' + NameCheck.regexPlanner + ')';
  static regexAllAdjacent = '(' + NameCheck.regexAdjacent + '|' + NameCheck.regexSuperPilot + ')';

  static isExecutive(name: string): boolean {
    return Boolean(name.toUpperCase().match(NameCheck.regexExecutive));
  }

  static isPlanner(name: string): boolean {
    return Boolean(name.toUpperCase().match(NameCheck.regexPlanner));
  }

  static isAdjacent(name: string): boolean {
    return Boolean(name.toUpperCase().match(NameCheck.regexAdjacent));
  }

  static isPilot(name: string): boolean {
    return Boolean(name.toUpperCase().match(NameCheck.regexPilot));
  }

  static isSuperPilot(name: string): boolean {
    return Boolean(name.toUpperCase().match(NameCheck.regexSuperPilot));
  }

  static isController(name: string): boolean {
    return NameCheck.isExecutive(name) || NameCheck.isPlanner(name);
  }

  static isAllPilot(name: string): boolean {
    return NameCheck.isPilot(name) || NameCheck.isSuperPilot(name);
  }
}

export { NameCheck };
