<script setup lang="ts">
import PositionCard from '@/components/lobby/PositionCard.vue';
import type { WorkspaceAssignment } from '@/model/WorkspaceAssignment';

interface Props {
  workspaces: WorkspaceAssignment[];
  title: string;
  disable?: boolean;
}

const { workspaces, title } = defineProps<Props>();
const emits = defineEmits(['selectWorkspace']);

const selectWorkspace = (id: number, selected: boolean, vrrSelected: boolean) => {
  emits('selectWorkspace', id, selected, vrrSelected);
};
</script>
<template>
  <div data-testid="sector-row">
    <h5 class="q-my-md">{{ title }}</h5>
    <div class="row q-gutter-md">
      <position-card
        v-for="workspace in workspaces"
        :id="workspace.id"
        :key="workspace.id"
        :position-type="workspace.positionType"
        :position="workspace.position"
        :vrr-state="workspace.vrrState"
        :assignable="workspace.assignable"
        :assignee="workspace.assignee"
        :selected="workspace.selected"
        :vrr-selected="workspace.vrrSelected"
        :atsetup-position="workspace.atsetupPosition"
        :user-display-name="workspace.userDisplayName"
        :sectors="workspace.sectors"
        :disable="disable"
        @select-workspace="selectWorkspace"
      ></position-card>
    </div>
  </div>
</template>
