class ScheduledCall<T> {
  private readonly name: string;
  private timer: number | undefined | ReturnType<typeof setInterval> = undefined;
  private readonly timeout: number;
  private readonly callback;

  /**
   * @param timeout milliseconds to wait between executing the callback
   * @param callback callback to execute
   */
  constructor(name: string, timeout: number, callback: () => T) {
    this.name = name;
    this.timeout = timeout;
    this.callback = callback;
  }

  /**
   * Start repeated execution
   */
  start(): void {
    if (this.timer == undefined) {
      console.debug(`starting to poll. name=${this.name}, timeout=${this.timeout}`);
      this.callback();
      this.timer = setInterval(this.callback, this.timeout);
    }
  }

  /**
   * Stop repeated execution
   */
  stop() {
    if (this.timer != undefined) {
      console.debug(`stopping poll. name=${this.name}`);
      clearInterval(this.timer);
    }
  }
}

export { ScheduledCall };
