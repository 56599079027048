<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, type PropType, type Ref, ref, watch } from 'vue';
import ListElement from '@/components/overview/ListElement.vue';
import ActionButton from '@/components/overview/ActionButton.vue';
import type { AvailableListFilters } from '@/components/overview/ListFilters';
import {
  BaseAvailableExerciseProvider,
  type ExerciseDataCallback,
} from '@/components/overview/BaseAvailableExerciseProvider';

import { RestApiRequest } from '@/restApiRequest';
import { useLoginStore } from '@/stores/LoginStore';
import type { ExerciseInfo } from '@/model/ExerciseInfo';
import ExerciseInfoDialog from '@/components/overview/ExerciseInfoDialog.vue';
import router from '@/router';
import { HttpError } from '@/connection/HttpError';
import { errorDialog, multipleSessionsDialog, warningDialog } from '@/components/dialogs/dialogs';
import type { SessionInfo } from '@/model/SessionInfo';
import type { NavigationFailure } from 'vue-router';

export interface ExpandCourseModel {
  [key: string]: boolean;
}

const expandCourseModel = defineModel<ExpandCourseModel>({ default: {} });

const props = defineProps({
  filters: {
    type: Object as PropType<AvailableListFilters>,
    required: true,
  },
  exercises: {
    type: BaseAvailableExerciseProvider,
    required: true,
  },
});

const emit = defineEmits(['toggle']);

const loginStore = useLoginStore();
const availableExercises: Ref<ExerciseInfo[]> = ref<ExerciseInfo[]>([]);
const spinnerVisible = ref(true);
const showInfo = ref(false);
const selectedInfo = ref<ExerciseInfo>({
  id: -1,
  name: 'Unknown exercise',
  directory: '',
  courseName: '',
  description: 'No information available',
  simulationMode: '',
  hasVrr: false,
  simulatedFacility: '',
  sectorNames: [],
  duration: 0,
  startTime: '',
  startDate: '',
  offsetTime: 0,
});

function filteredCourseExercises(course: string): ExerciseInfo[] {
  return availableExercises.value
    .filter((exercise) => exercise.courseName === course)
    .filter((exercise) => filterSimulationMode(exercise))
    .filter((exercise) => matchTextFilter(exercise))
    .filter((exercise) => filterVrr(exercise));
}

const courses = computed(() => {
  const collectCourses: string[] = [];
  availableExercises.value.forEach((ex) => {
    if (!collectCourses.includes(ex.courseName)) {
      collectCourses.push(ex.courseName);
    }
  });
  return collectCourses;
});

const updateExerciseMessages: ExerciseDataCallback = (exerciseMessages: ExerciseInfo[]) => {
  spinnerVisible.value = false;
  availableExercises.value = exerciseMessages;
  if (!loginStore.isAdmin && availableExercises.value.length == 0) {
    warningDialog(
      'Warning',
      'No exercises for user found! Please contact your administrator! Return to login page',
    ).onOk(async () => {
      const routingResult: NavigationFailure | void | undefined = await router.push({
        name: 'login_view',
      });
      if (!routingResult) {
        await loginStore.logout();
      }
    });
  }
};

const exerciseProvider: BaseAvailableExerciseProvider = props.exercises;
let subscriptionId: string = '';
onMounted(() => {
  subscriptionId = exerciseProvider.subscribeForExerciseMessages(updateExerciseMessages);
  exerciseProvider.start();
});
onBeforeUnmount(() => {
  exerciseProvider.stop();
  exerciseProvider.unsubscribeFromExerciseMessages(subscriptionId);
});

const filterSimulationMode = (exercise: ExerciseInfo): boolean => {
  return (
    props.filters.selectedSimModes.length === 0 ||
    props.filters.selectedSimModes.includes(exercise.simulationMode)
  );
};

function matchTextFilter(exercise: ExerciseInfo): boolean {
  if (props.filters.filterText.length == 0) {
    return true;
  }
  const filterTextUpperCase = props.filters.filterText.toUpperCase();
  if (exercise.description.toUpperCase().includes(filterTextUpperCase)) {
    return true;
  }
  if (exercise.name.toUpperCase().includes(filterTextUpperCase)) {
    return true;
  }
  return false;
}

function filterVrr(exercise: ExerciseInfo): boolean {
  if (!props.filters.vrrFilterOn) {
    return true;
  }
  return exercise.hasVrr;
}

function openInfo(exercise: ExerciseInfo) {
  showInfo.value = true;
  selectedInfo.value = exercise;
}

let createSessionLocked = false;

function createSession(exercise: ExerciseInfo) {
  if (createSessionLocked) {
    return;
  }
  createSessionLocked = true;

  RestApiRequest.createSession(exercise.id, loginStore.csrfToken)
    .then((sessionId: string) =>
      router.push({
        name: 'lobby',
        params: { sessionId: sessionId, exerciseName: exercise.name },
      }),
    )
    .catch((error: unknown) => {
      if (!(error instanceof HttpError)) {
        errorDialog(`Failed to create session! Reason: ${error}`).onOk(() => {
          createSessionLocked = false;
        });
        return;
      }

      if (!error.jsonBody || error.jsonBody['errorType'] !== 'MultipleSessions') {
        throw error;
      }

      const existingSessionId = error.jsonBody['sessionId'];
      RestApiRequest.getSessionInfo(loginStore.csrfToken, existingSessionId)
        .then((sessionInfo) => showMultipleSessionWarning(existingSessionId, sessionInfo))
        .catch(() => showMultipleSessionWarning(existingSessionId, undefined));
    });
}

function showMultipleSessionWarning(
  existingSessionId: string,
  sessionInfo: SessionInfo | undefined,
) {
  multipleSessionsDialog()
    .onOk(async () => {
      await router.push({
        name: 'lobby',
        params: {
          sessionId: existingSessionId,
          exerciseName: sessionInfo ? sessionInfo.exercise.name : 'N/A',
        },
      });
    })
    .onCancel(() => {
      createSessionLocked = false;
    });
}

watch(courses, setExpandCourseModel);

function setExpandCourseModel() {
  const newExpandCourseModel: ExpandCourseModel = {};
  courses.value.forEach((course) => {
    const expanded: boolean =
      expandCourseModel.value[course] === undefined ? true : expandCourseModel.value[course];
    newExpandCourseModel[course] = expanded;
  });
  expandCourseModel.value = newExpandCourseModel;
}
</script>

<template>
  <q-scroll-area data-testid="available-exercise-list">
    <div class="q-ma-xs">
      <template v-for="course in courses.values()" :key="course">
        <q-expansion-item
          v-model="expandCourseModel[course]"
          :label="course"
          default-opened
          dense
          switch-toggle-side
          header-class="text-bold text-h6"
          data-testid="course-name"
          @click="emit('toggle')"
        >
          <div class="q-ma-md">
            <q-list separator>
              <q-item
                v-for="exercise in filteredCourseExercises(course)"
                :key="exercise.id"
                class="q-card q-card--bordered q-mb-sm"
              >
                <ListElement>
                  <template #first-line>
                    <q-item-label class="exercise-text" lines="1">
                      <span>{{ exercise.name }}</span>
                    </q-item-label>
                  </template>
                  <template #second-line>
                    <q-item-label caption lines="1">
                      {{ exercise.simulationMode }} - {{ exercise.description }}
                    </q-item-label>
                  </template>
                  <template #additional>
                    <q-icon v-if="exercise.hasVrr" name="record_voice_over" />
                  </template>
                  <template #info-button>
                    <q-btn
                      round
                      dense
                      flat
                      size="11px"
                      icon="info_outline"
                      @click="openInfo(exercise)"
                    >
                      <q-tooltip self="bottom left">Show more exercise details</q-tooltip>
                    </q-btn>
                  </template>
                  <template #action-button>
                    <ActionButton tool-tip-text="Start Exercise">
                      <template #button>
                        <q-btn class="btn-small bg-secondary" @click.once="createSession(exercise)">
                          Start
                        </q-btn>
                      </template>
                    </ActionButton>
                  </template>
                </ListElement>
              </q-item>
            </q-list>
          </div>
        </q-expansion-item>
      </template>
    </div>
    <ExerciseInfoDialog v-model:show-info="showInfo" :exercise-info="selectedInfo" />
    <q-inner-loading
      :showing="spinnerVisible"
      label="Fetching exercises, please wait..."
      label-class="text-secondary"
      label-style="font-size: 1.1em"
    />
  </q-scroll-area>
</template>

<style scoped lang="sass"></style>
