<script setup lang="ts">
import type { DashboardSessionInfo } from '@/model/DashboardSessionInfo';
import type { QTableColumn } from 'quasar';
import { isSimulatorSessionStateType } from '@/model/SimulatorSessionState';
import { useLoginStore } from '@/stores/LoginStore';
import { RestApiRequest } from '@/restApiRequest';
import { deleteSessionDialog } from '@/components/dialogs/dialogs';

const loginStore = useLoginStore();

interface Props {
  sessionInfos: DashboardSessionInfo[];
}

const propData = defineProps<Props>();

async function showDeleteSessionDialog(sessionId: string) {
  console.log(`delete the session with id ${sessionId}`);

  deleteSessionDialog(sessionId).onOk(() => {
    terminateSession(sessionId);
  });
}

async function terminateSession(sessionId: string) {
  console.debug(`terminating session. sessingId=${sessionId}`);
  await RestApiRequest.terminateSession(sessionId, loginStore.csrfToken);
}

const columns: QTableColumn[] = [
  {
    name: 'name',
    required: true,
    label: 'Session ID',
    align: 'left',
    field: (row) => row.name,
    format: (val) => `${val}`,
    sortable: true,
  },
  { name: 'status', align: 'left', label: 'Status', field: 'status', sortable: true },
  { name: 'action', align: 'left', label: 'Action', field: 'action' },
];

const rows = propData.sessionInfos;

const getStatusIcon = (statusValue: string) => {
  if (statusValue.toUpperCase() === 'TERMINATE') {
    return 'warning';
  } else if (isSimulatorSessionStateType(statusValue.toUpperCase())) {
    return 'check_circle';
  }
  return 'error';
};

const getStatusColor = (statusValue: string) => {
  if (statusValue.toUpperCase() === 'TERMINATE') {
    return 'orange';
  } else if (statusValue.toUpperCase() === 'ERROR') {
    return 'red';
  } else if (isSimulatorSessionStateType(statusValue.toUpperCase())) {
    return 'green';
  }
  return 'red';
};

const initialPagination = {
  sortBy: 'name',
  descending: false,
  page: 1,
  rowsPerPage: 10,
};
</script>

<template>
  <div class="q-pa-md" data-testid="session-list">
    <q-table
      class="table-layout"
      title="List of Sessions"
      dense
      :rows="rows"
      :columns="columns"
      row-key="name"
      :pagination="initialPagination"
      no-data-label="no active sessions"
    >
      <template #body-cell-status="props">
        <q-td :props="props">
          <div>
            <q-icon
              size="sm"
              :name="getStatusIcon(props.value)"
              :color="getStatusColor(props.value)"
            />
            {{ props.value }}
          </div>
        </q-td>
      </template>
      <template #body-cell-action="props">
        <q-td :props="props">
          <q-icon
            class="clickable"
            size="sm"
            name="delete_outline"
            @click="showDeleteSessionDialog(props.row.name)"
          />
        </q-td>
      </template>
      <template #no-data="{ message }">
        <div class="row flex-center q-gutter-sm">
          <q-icon size="sm" name="playlist_add_check" />
          <span>
            {{ message }}
          </span>
        </div>
      </template>
    </q-table>
  </div>
</template>

<style scoped lang="sass">
.clickable:hover
  cursor: pointer
</style>
