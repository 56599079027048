import { reactive } from 'vue';

type PasswordValidator = {
  length: boolean;
  capital: boolean;
  small: boolean;
  number: boolean;
  symbol: boolean;
};

const validPassword: PasswordValidator = reactive(<PasswordValidator>{
  length: false,
  capital: false,
  small: false,
  number: false,
  symbol: false,
});

const validatePassword = (password: string): boolean => {
  validPassword.length = password.length >= 8 && password.length <= 30;

  validPassword.capital = /^(?=.*[A-Z])/.test(password);

  validPassword.small = /^(?=.*[a-z])/.test(password);

  validPassword.number = /^(?=.*[0-9])/.test(password);

  validPassword.symbol = /^(?=.*[@$!%*?&])/.test(password);

  return (
    validPassword.length &&
    validPassword.capital &&
    validPassword.small &&
    validPassword.number &&
    validPassword.symbol
  );
};

export { validatePassword };
