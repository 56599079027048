import type { SessionInfo } from '@/model/SessionInfo';
import type { SimulationMode } from '@/enums/SimulationMode';
import { getSimulationMode } from '@/enums/SimulationMode';

interface InfoData {
  exerciseName: string;
  simulationMode: SimulationMode;
  simulationDate: string;
  simulationStartTime: string;
  exerciseDuration: number | string;
}

const getInfoData = (session: SessionInfo | undefined): InfoData => {
  return {
    exerciseName: session ? session.exercise.name : '',
    simulationMode: getSimulationMode(session),
    simulationDate: session ? session.exercise.startDate : '',
    simulationStartTime: session ? session.exercise.startTime : '',
    exerciseDuration: session ? session.exercise.duration : '',
  };
};

export type { InfoData };
export { getInfoData };
