<script setup lang="ts">
import type { NamedColor } from 'quasar';

interface Props {
  label: string;
  color: NamedColor;
  disable?: boolean;
  tooltip?: string;
}

const { label, color, disable = false, tooltip = '' } = defineProps<Props>();
const emit = defineEmits(['click']);

const onClick = () => {
  emit('click');
};
</script>

<template>
  <q-btn
    class="btn-fixed-width-l"
    :label="label"
    :color="color"
    :disable="disable"
    @click="onClick"
  >
    <q-tooltip v-if="tooltip" self="bottom left" :delay="Number(700)">
      {{ tooltip }}
    </q-tooltip>
  </q-btn>
</template>

<style scoped lang="sass"></style>
