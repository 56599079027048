<script setup lang="ts">
import { useRoute } from 'vue-router';
import { onBeforeMount, onUnmounted, watch } from 'vue';
import { RestApiRequest } from '@/restApiRequest';
import { useLoginStore } from '@/stores/LoginStore';
import { FetchSessionInfoLobby } from '@/components/helper/FetchSessionInfoLobby';
import type { SessionReadiness } from '@/model/SessionReadiness';
import { HttpError } from '@/connection/HttpError';
import { sessionErrorDialog } from '@/components/dialogs/dialogs';

const route = useRoute();
const loginStore = useLoginStore();

const sessionId = Array.isArray(route.params.sessionId)
  ? route.params.sessionId[0]
  : route.params.sessionId;

const fetchSessionInfo = new FetchSessionInfoLobby(
  sessionId,
  loginStore.csrfToken,
  loginStore.userName,
);
const { sessionReadiness, sessionInfoScheduler, sessionError } = fetchSessionInfo.get();

onBeforeMount(() => {
  sessionInfoScheduler.start();
});

onUnmounted(() => {
  sessionInfoScheduler.stop();
});

watch(sessionReadiness, watchSessionState, { immediate: true });
watch(sessionError, showSessionErrorDialog, { immediate: true });

async function watchSessionState(newState: SessionReadiness) {
  if ('STARTED' !== newState) {
    return;
  }
  try {
    const workingPositionUri = await RestApiRequest.getWorkingPositionUri(
      sessionId,
      loginStore.csrfToken,
    );
    if (typeof workingPositionUri !== 'string') {
      sessionError.value = new HttpError(400, 'unparsable data');
      return;
    }
    window.open(`https://${workingPositionUri}`, '_self');
  } catch (error) {
    if (error instanceof HttpError) {
      sessionError.value = error;
    }
  }
}

async function showSessionErrorDialog(newValue: HttpError | undefined) {
  if (!newValue) {
    return;
  }
  sessionErrorDialog().onOk(() => {
    window.close();
  });
}
</script>

<template>
  <q-layout>
    <q-page-container>
      <q-page class="flex flex-center column" style="background-color: rgba(0, 0, 0, 0.2)">
        <div>
          <q-spinner-tail color="primary" size="140" />
        </div>
        <div>
          <h1>Working position is starting...</h1>
        </div>
      </q-page>
    </q-page-container>
  </q-layout>
</template>
