<script setup lang="ts">
import type { InfoData } from '@/components/backstage/InfoArea';
import type { SimulationTimes } from '@/model/SimulationTimes.ts';
import type { QTableColumn } from 'quasar';
import { computed } from 'vue';

interface Props {
  infoData: InfoData;
  simulationTimes: SimulationTimes | undefined;
}

const { infoData, simulationTimes } = defineProps<Props>();

const columns: QTableColumn[] = [
  {
    name: 'name',
    required: true,
    label: 'Name',
    align: 'left',
    field: (row) => row.name,
    format: (val) => `${val}`,
    style: 'width: 15vw',
  },
  { name: 'content', align: 'left', label: 'Content', field: 'content' },
];

const setStartDateTime = () => {
  if (infoData.simulationStartTime.length > 0) {
    return infoData.simulationStartTime + ', ' + infoData.simulationDate;
  }
  return simulationTimes?.startTime ?? 'UTC';
};

const items = computed(() => {
  const startTimeDate = setStartDateTime();
  const absSimTime = simulationTimes?.absoluteTime ?? '--:--:--';
  const relSimTime = simulationTimes?.relativeTime ?? '--:--:--';
  return [
    { name: 'Exercise Name:', content: infoData.exerciseName },
    { name: 'Simulation Mode:', content: infoData.simulationMode },
    { name: 'Duration:', content: infoData.exerciseDuration + ' min' },
    { name: 'Start Time/Date:', content: startTimeDate },
    { name: 'Current Simulation Time:', content: absSimTime },
    { name: 'Relative Simulation Time:', content: relSimTime },
  ];
});
</script>

<template>
  <q-table
    hide-header
    hide-bottom
    :rows-per-page-options="[0]"
    :rows="items"
    :columns="columns"
    row-key="name"
    dense
  ></q-table>
</template>

<style scoped lang="sass" />
