import type { SessionReadiness } from '../model/SessionReadiness';

export const StateValues = [
  'SETUP',
  'NOTRUNNINGYET',
  'STARTING',
  'RUNNING',
  'PAUSE',
  'TERMINATE',
  'UNKNOWN',
  'ERROR',
] as const;

export type SimulatorSessionState = (typeof StateValues)[number];

export function isSimulatorSessionStateType(value: string): boolean {
  return StateValues.includes(value as SimulatorSessionState);
}

export function isRunning(state: SimulatorSessionState) {
  return 'RUNNING' === state || 'PAUSE' === state || 'STARTING' === state;
}

export function mapSessionState(
  state: SimulatorSessionState,
  participantsReady?: boolean,
): SessionReadiness {
  switch (state) {
    case 'SETUP':
      return participantsReady ? 'WAITING_FOR_BACKEND' : 'WAITING_FOR_READY';
    case 'NOTRUNNINGYET':
      return participantsReady ? 'READY' : 'WAITING_FOR_READY';
    case 'STARTING':
      return 'STARTING';
    case 'RUNNING':
    case 'PAUSE':
      return 'STARTED';
    default:
      return 'ERROR';
  }
}
